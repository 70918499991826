<template>
  <div class="reservation-detail" :class="{skeleton: !state.loaded, fixed: state.fixed}">
    <div class="summary">
      <div class="container">
        <div class="row">
          <div class="left col-lg-8">
            <div class="image">
              <!--              <span class="img" :style="{backgroundImage: `url(${computedThumbnailUrl})`}"></span>-->
              <span class="img" :style="{backgroundImage: `url('${state.project.thumbnailPath}')`}"></span>
            </div>
          </div>
          <div class="right col-lg-4">
            <div class="wrapper">
              <div class="subject font-base">
                <span class="badge badge-primary font-xs">{{ state.project.reservationCate === "invest" ? "증권형" : "후원형" }}</span>
                <div class="txt">
                  <b>{{ state.project.projectName }}</b>
                </div>
              </div>
              <div class="section" v-if="state.project.hideOpenDateYn !== 'Y'">
                <i class="fa fa-check color-point"></i>
                <b>{{ state.project.openDate }}</b>
                <span> 오픈예정</span>
              </div>
              <div class="section">
                <i class="fa fa-check color-point"></i>
                <b>{{ $lib.getNumberFormat(state.project.applyCnt) }}명</b>
                <span> 알림 신청</span>
              </div>
            </div>
            <MakerInfo
                :builderSeq="state.project.openId || state.project.memberSeq || state.project.builderSeq"
                :projectType="state.project.reservationCate"
            />
            <div class="act join">
              <ReservationBtn
                  :applyFlag="state.project.applyFlag"
                  :change="onChange"
                  :clickable="true"
                  :imgUrl="computedThumbnailUrl"
                  :link="'/reservation/' + state.project.reservationSeq"
                  page="detail"
                  :projectName="state.project.projectName"
                  :reservationSeq="state.project.reservationSeq"
                  :reservationCate="state.project.reservationCate"
                  :skeleton="!state.loaded"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="fixed-fit" ref="fixedTopRef">
        <div class="container">
          <div class="clearfix">
            <div class="name">
              <b>{{ state.project.projectName }}</b>
            </div>
            <div class="act">
              <ReservationBtn
                  :applyFlag="state.project.applyFlag"
                  :change="onChange"
                  :clickable="true"
                  :imgUrl="computedThumbnailUrl"
                  :link="'/reservation/' + state.project.reservationSeq"
                  page="detail"
                  :projectName="state.project.projectName"
                  :reservationSeq="state.project.reservationSeq"
                  :reservationCate="state.project.reservationCate"
                  :skeleton="!state.loaded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="container">
        <div class="wrapper" ref="contentWrapperRef">
          <div class="edit-style" v-html="state.project.content"></div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="container">
        <router-link to="/reservation/list" class="font-sm">
          <i class="fa fa-angle-left"></i>
          <span>프로젝트 목록으로</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, onUnmounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import Card from "@/components/Card";
import router from "@/scripts/router";
import MemberPicture from "@/components/MemberPicture";
import store from "@/scripts/store";
import definitions from "@/scripts/definitions";
import ReservationBtn from "@/components/ReservationBtn";
import track from "@/scripts/track";
import Anchor from "@/components/Anchor";
import MakerInfo from "@/components/MakerInfo";

function Component(initialize) {
  this.name = "pageReservationDetail";
  this.initialize = initialize;
}

export default defineComponent({
  components: {MakerInfo, Anchor, ReservationBtn, MemberPicture, Card},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      state.loaded = false;
      http.get(`/api/reservations/${router.app.$route.params.projectSeq}`).then(({data}) => {
        state.loaded = true;
        state.project = data.body.project;

        if (!store.state.account.memberAuth.includes("ADMIN") && !state.project.detail) {
          return router.replace(`/reservation/list?reservationSeq=${state.project.reservationSeq}`);
        } else if (router.app.$route.query.preview !== "true" && !state.project.detail) {
          return router.replace(`/reservation/list?reservationSeq=${state.project.reservationSeq}`);
        }

        track.post({
          name: "reservationProjectDetail",
          category: "오픈예정",
          topic: "상세 조회",
          title: state.project.projectName,
        });

        // 이미 오픈이 된 경우 본 프로젝트로 이동
        if (state.project.openFlag === "Y" && state.project.reservationCate && state.project.projectSeq) {
          return router.replace(`/${state.project.reservationCate}/${state.project.projectSeq}`);
        }

        typeof state.project.reservationSeq === "string"
        && (state.project.reservationSeq = Number.parseInt(state.project.reservationSeq));

        typeof state.project.applyCnt === "string"
        && (state.project.applyCnt = Number.parseInt(state.project.applyCnt));

        store.dispatch("setDocumentTitle", state.project.projectName);
      }).catch(httpError());

      nextTick(() => {
        store.commit("addListener", [component.name, "onScroll", onScroll]);
      });
    });

    const state = reactive({
      loaded: false,
      fixed: false,
      project: {
        reservationSeq: 0,
        projectName: "Please wait a moment Please wait a moment",
        memberName: "Wait a moment",
        corporateName: "Wait a moment",
        detail: 0,
        reservationCate: "",
        content: "",
        filePath: "",
        fileSaveName: "",
        builderImageUrl: "",
        facebook: "",
        applyFlag: "",
        memberTel: "",
        thumbnailPath: "",
      }
    });

    const fixedTopRef = ref();
    const contentWrapperRef = ref();

    const computedThumbnailUrl = computed(() => {
      return store.getters.thumbnailUrl(state.project.thumbnailPath);
    });

    const openMessageModal = () => {
      if (lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      } else if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      store.commit("openModal", {
        name: "MessageWrite",
        params: {
          type: "send",
          members: [{
            seq: state.project.reservationCate === "invest" ? state.project.openId : state.project.openId,
            name: state.project.reservationCate === "invest" ? state.project.corporateName : state.project.memberName,
          }]
        }
      });
    };

    const onChange = (applyFlag) => {
      state.project.applyFlag = applyFlag;
      state.project.applyCnt = state.project.applyCnt + (applyFlag === "0" ? -1 : 1);
    };

    const onScroll = () => {
      if (!contentWrapperRef.value) {
        return;
      }

      const top = contentWrapperRef.value.getBoundingClientRect().top - lib.getHeaderHeight();

      state.fixed = top <= 0;

      store.commit("addComponentClass", {to: "header", value: "mobile-no-fixed"});
      store.commit("addComponentClass", {to: "ground", value: "mobile-no-padding-top"});

      if (state.fixed) {
        store.commit("addComponentClass", {to: "header", value: "transparency"});
      } else {
        store.commit("removeComponentClass", {to: "header", value: "transparency"});
      }
    };

    onUnmounted(() => {
      store.commit("removeComponentClass", {to: "header", value: "mobile-no-fixed"});
      store.commit("removeComponentClass", {to: "ground", value: "mobile-no-padding-top"});
      store.commit("removeComponentClass", {to: "header", value: "transparency"});
    });

    return {component, state, fixedTopRef, contentWrapperRef, computedThumbnailUrl, openMessageModal, onChange};
  }
});
</script>

<style lang="scss" scoped>
.reservation-detail {
  padding: $px30 0 $px60 0;

  > .summary {
    padding: $px30 0;

    > .container {
      > .row {
        .left {
          .image {
            border: $px1 solid #eee;
            background: $colorBackground;
            position: relative;
            overflow: hidden;
            padding-bottom: $ratio43Percent;

            .img {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          > .wrapper {
            .subject {
              margin-bottom: $px28;

              .txt {
                font-size: $px22;
                padding-top: $px5;
                min-height: $px71;

                b {
                  word-break: keep-all;
                }
              }
            }

            .profile {
              font-size: 0;
              margin-bottom: $px35;
              background: $colorBackground;
              border: $px1 solid #eee;
              padding: $px20 $px15;

              > .wrapper {
                position: relative;
                padding-left: $px70;

                > a {
                  .img {
                    margin-right: $px6;
                    position: absolute;
                    top: 0;
                    left: 0;
                  }

                  .txt {
                    margin-right: $px10;
                  }
                }

                .acts {
                  padding-top: $px9;
                  margin: 0 $px-2;

                  > .btn, > a {
                    margin: 0 $px2;
                    height: $px30;
                  }

                  > .btn {
                    padding: $px5 $px8;
                  }

                  > a {
                    width: $px30;
                    vertical-align: middle;
                    background-color: $colorBackground;
                    background-position: center;
                    border: $px1 solid #eee;
                    border-radius: $px4;
                    background-size: $px21;

                    &.homepage {
                      background-size: $px15;
                    }

                    &.email {
                      background-size: $px13;
                    }

                    &.blog {
                      background-size: $px13;
                    }
                  }
                }
              }
            }

            .section {
              margin-bottom: $px15;
              font-size: $px18;

              i {
                margin-right: $px10;
              }
            }
          }

          > .profile {
            margin-bottom: $px15;
          }
        }
      }
    }

    > .fixed-fit {
      background: #fff;
      border-bottom: $px1 solid #eee;
      padding: $px12;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;

      .name {
        float: left;
        padding: $px22 0;
      }

      .act {
        float: right;
        width: 50%;
        max-width: $px400;
      }
    }
  }

  > .content {
    > .container > .wrapper {
      border: $px1 solid #eee;
      padding: $px50 0;

      > div {
        max-width: 680px;
        margin: 0 auto;
      }
    }

    &::v-deep {
      img {
        height: auto;
      }
    }
  }

  > .bottom {
    padding: $px30 0;

    > .container > a {
      text-decoration: none;

      > i, > span {
        vertical-align: middle;
      }

      > i {
        margin-right: $px7;
        height: $px16;
      }

      > span {
        position: relative;
      }

      &:hover {
        font-weight: 500;
      }
    }
  }

  &.fixed {
    > .summary > .fixed-fit {
      opacity: 1;
      z-index: 100;
    }
  }

  &.skeleton {
    > .summary > .container > .row .right {
      > .wrapper {
        .subject {
          .badge,
          .txt b {
            @include skeleton;
          }
        }

        .profile > .wrapper {
          b.txt, > a .img {
            @include skeleton;
          }

          .acts {
            > .btn, > a {
              @include skeleton;
            }
          }
        }

        .section {
          i {
            display: none;
          }

          b, span {
            @include skeleton;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    > .summary {
      padding-top: 0;
      padding-bottom: 0;

      > .fixed-fit {
        padding: $px15 0;

        .name {
          padding-top: $px9;
        }

        .act {
          width: 100%;
        }
      }

      > .container > .row .right {
        > .wrapper {
          .subject {
            margin-top: $px20;
            margin-bottom: $px15;

            .txt {
              font-size: $px20;
              min-height: auto;
            }
          }

          .section {
            margin-bottom: $px10;
          }
        }

        > .profile {
          margin-top: $px15;
          margin-bottom: $px30;
        }
      }
    }

    > .content {
      > .container > .wrapper {
        border: 0;
        padding: $px30 0 0 0;
      }
    }
  }
}
</style>