<template>
  <div class="slider swiper" :id="`${component.name}Slider`" :class="{skeleton : !state.loaded }">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, idx) in state.items" :key="idx">
        <!-- location.origin 부분을 빈 값으로 REPLACE 하고 싶다면 UTM 트래킹 부분 고려해야 함. -->
        <Anchor class="wrapper" :href="item.bannerUrl">
          <span class="img" :style="{backgroundImage: `url(${item.imagePath + item.imageSaveName})`}"></span>
          <div class="bg" :style="{backgroundColor: item.bgColor || '#000'}"></div>
          <div :id="`${component.name}Pagination`" class="pagination">
            <span>{{ state.swiper ? state.swiper.realIndex + 1 : 1 }}</span>
            <span>/</span>
            <span>{{ state.items.length }}</span>
          </div>
          <div class="container">
            <div class="text">
              <div class="wrapper">
                <div class="title">
                  <span :style="{color: item.titleColor || '#fff'}">{{ item.title }}</span>
                </div>
                <div class="desc">
                  <span :style="{color: item.titleColor || '#fff'}">{{ item.communityDesc }}</span>
                </div>
              </div>
            </div>
          </div>
        </Anchor>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "componentSlider";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({
      loaded: false,
      items: [{
        imagePath: "",
        imageSaveName: "",
        title: "Wait a moment\nWait a moment",
        communityDesc: "Please wait a moment",
        bannerUrl: "",
        titleColor: "",
      }],
      swiper: null
    });

    const swiperRef = ref();

    const instantiate = () => {
      state.swiper = new window.Swiper(`#${component.name}Slider`, {
        spaceBetween: 30,
        effect: "fade",
        loop: true,
        autoHeight: true,
        autoplay: {
          delay: 4000,
        },
        // pagination: {
        //   el: `#${component.name}Pagination`,
        //   clickable: true,
        // }
      });
    };

    onMounted(() => {
      state.loaded = false;

      http.get("/api/main/banners/big", undefined, {cache: true}).then(({data}) => {
        state.loaded = true;
        state.items = data.body;
        nextTick(instantiate);
      }).catch(httpError());
    });

    return {component, state, swiperRef,};
  },
});
</script>

<style lang="scss" scoped>
.slider::v-deep {
  background: $colorBackground;

  .swiper-wrapper {
    > .swiper-slide {
      background: $colorBackground;

      > .wrapper {
        display: block;
        position: relative;
        height: 400px;

        > .img {
          background-position: center;
          background-size: cover;
          display: block;
          height: 100%;
          width: 100%;
        }

        .bg {
          position: absolute;
          filter: blur(52px);
          bottom: $px-40;
          left: $px-65;
          opacity: 0.3;
          height: 40%;
          width: 120%;
        }

        > .pagination {
          position: absolute;
          right: $px36;
          top: $px36;
          z-index: 1;
          display: flex;
          gap: $px4;
          background: rgba(#000, 0.4);
          border-radius: $px100;
          color: #fff;
          font-size: $px14;
          padding: $px4 $px8;
        }

        > .container {
          position: absolute;
          bottom: $px36;
          left: 50%;
          transform: translateX(-50%);

          .text {
            width: 50%;
            color: #fff;
            padding: 0 $px24;

            > .wrapper {
              > .title {
                cursor: pointer;
                font-size: $px28;
                line-height: 1.5;
                font-weight: 700;
                padding-bottom: $px20;
                white-space: pre-line;
              }

              > .desc {
                cursor: pointer;
                font-size: $px18;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  &.skeleton {
    > .swiper-wrapper > .swiper-slide > .wrapper {
      .text > .wrapper {
        > .title > span {
          @include skeleton;
        }

        > .desc > span {
          @include skeleton;
        }
      }

      .image > .img {
        @include skeleton;
      }
    }
  }

  @media(max-width: 1199px) {
    > .swiper-wrapper {
      > .swiper-slide > .wrapper .image {
        width: 600px;
      }

      .pagination {
        right: $px20;
        top: 0;
      }
    }
  }

  @media(max-width: 991px) {
    > .swiper-wrapper {
      > .swiper-slide {
        padding: 0;
        border-width: 0;

        > .wrapper {
          cursor: pointer;
          overflow: hidden;

          .bg {
            filter: blur(50px);
          }

          .pagination {
            right: $px18;
            top: $px19;
            font-size: $px12;
            padding: $px4 $px8;
          }

          > .container {
            bottom: $px5;

            > .text {
              transform: none;
              text-align: center;
              padding-bottom: $px25;
              width: 100%;

              > .wrapper {
                position: relative;

                > .title {
                  padding-bottom: $px8;
                  font-size: $px24;
                  word-break: keep-all;
                  line-height: 1.35;
                }

                > .desc {
                  font-size: $px16;
                }
              }
            }
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    > .swiper-wrapper > .swiper-slide > .wrapper {

    }
  }
}
</style>