<template>
  <div class="page" v-if="info.lastPageNoOnPageList > 1">
    <template v-if="info.firstPageNoOnPageList > info.pageSize">
      <button class="btn btn-default font-xs pl-2 pr-2" @click="click(info.firstPageNo)" :title="`첫 페이지로 이동`">처음</button>
      <button class="btn btn-default" @click="click(info.firstPageNoOnPageList - 1)" title="이전 페이지로 이동">
        <i class="fa fa-angle-left"></i>
      </button>
    </template>
    <button class="btn font-xs" :class="[info.currentPageNo === n ? 'btn-point-soft active' : 'btn-default']" v-for="(n, idx) in state.numbers" :key="idx" @click="click(n)">{{ n }}</button>
    <template v-if="info.lastPageNoOnPageList < info.lastPageNo">
      <button class="btn btn-default" @click="click(info.lastPageNoOnPageList + 1)" title="다음 페이지로 이동">
        <i class="fa fa-angle-right"></i>
      </button>
      <button class="btn btn-default font-xs pl-2 pr-2" @click="click(info.lastPageNo)" :title="`마지막 페이지(${info.lastPageNo})로 이동`">마지막</button>
    </template>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentPagination";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    info: Object,
    change: Function,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({
      numbers: computed(() => {
        const arr = [];

        for (let i = props.info.firstPageNoOnPageList; i <= props.info.lastPageNoOnPageList; i += 1) {
          arr.push(i);
        }

        return arr;
      })
    });

    const click = (num) => {
      if (typeof props.change === "function" && props.info.currentPageNo !== num) {
        props.info.currentPageNo = num;
        props.change(num);
      }
    };

    return {component, state, click};
  },
});
</script>

<style lang="scss" scoped>
.page {
  text-align: center;

  .btn {
    min-width: $px33;
    height: $px33;
    line-height: $px31;
    padding: 0 $px5;
    border: 0;
    border-radius: $px100;

    &:not(.active) {
      background: #fff;

      &:hover {
        background: #eee;
      }
    }
  }
}
</style>