<template>
  <div class="partnership" :class="{ skeleton : !state.loaded }">
    <ul class="tight no-scrollbar">
      <template v-if="state.loaded">
        <template v-if="state.items.length">
          <li v-for="(p, idx) in state.items" :key="idx" :class="{skeleton:p.placeholder}">
            <PartnerCard
                :link="$store.getters.partnerCardLink(p.contestSeq, p.contestType)"
                :contestSeq="p.contestSeq"
                :thumbImagePath="p.thumbImagePath"
                :thumbImageSaveName="p.thumbImageSaveName"
                :partnershipName="p.partnershipName"
                :contestName="p.contestName"
                :contestExplanationText="p.contestExplanationText"
                :projectCount="p.projectCount"
                :sumAmt="p.sumAmt"
                :progressOrder="p.progressOrder"
                page="home"
            />
          </li>
        </template>
      </template>
      <template v-else>
        <li v-for="i in 4" :key="i">
          <PartnerCard
              link=""
              :skeleton="true"
              :contestSeq="0"
              partnershipName="Wait a moment"
              contestName="Wait a moment"
              contestExplanationText="Wait a moment"
              :rewardProjectCount="0"
              :rewardProjectSumAmt="0"
              page="home"
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive,} from "@vue/composition-api";
import http from "@/scripts/http";
import mixin from "@/scripts/mixin";
import PartnerCard from "@/components/PartnerCard.vue";

function Component(initialize) {
  this.name = "pageMainHomePartnership";
  this.initialize = initialize;
}

export default defineComponent({
  components: {PartnerCard},
  mixins: [mixin],
  props: {
    loaded: Boolean
  },
  setup(props, {emit}) {
    const component = new Component(() => {
    });

    const state = reactive({
      items: [],
      loaded: false
    });

    onMounted(() => {
      state.loaded = false;
      emit("update:loaded", state.loaded);

      http.get("/api/main/partnerships", undefined, {cache: true}).then(({data}) => {
        state.loaded = true;
        state.items = data.body;
        emit("update:loaded", state.loaded);
      });
    });

    return {component, state};
  },
});
</script>

<style lang="scss" scoped>
.partnership {
  ul {
    display: flex;
    gap: $px16;
    margin: 0 -15px;
    overflow: auto;

    li {
      display: inline-block;
      width: calc((100% - ($px16 * 3)) / 4);

      &:first-child {
        margin-left: 15px;
      }

      &:last-child {
        margin-right: 15px;
      }
    }
  }

  @media(max-width: 991px) {
    ul {
      white-space: nowrap;
      gap: $px10;

      li {
        width: 44%;
        flex-shrink: 0;
      }
    }
  }
}
</style>