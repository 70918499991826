<template>
  <header class="bg-gradient-blue" :class="{ collapsed }">
    <div class="wrapper">
      <RouterLink to="/new-admin" class="title">관리자 페이지</RouterLink>
      <div class="status">
        <!--        <span>총 펀딩 참여자 </span>-->
        <!--        <b>{{ $lib.getNumberFormat(state.funding.investorCnt) }}명</b>-->
        <!--        <span class="d-none d-sm-inline"> / </span>-->
        <!--        <br class="d-inline d-sm-none"/>-->
        <!--        <span>총 펀딩 금액 </span>-->
        <!--        <b>{{ $lib.getNumberFormat(state.funding.investorAmt) }}원</b>-->
        <span>추후 펀딩 참여자 및 펀딩 금액이 표시될 예정입니다.</span>
      </div>
      <div class="side">
        <RouterLink to="/mypage" target="_blank" rel="noopener noreferrer" class="profile" title="마이페이지로 이동" v-if="$store.state.account.loggedIn">
          <MemberPicture :memberSeq="$store.state.account.memberSeq"/>
          <div class="wrapper">
            <span class="badge bg-default color-white">관리자</span>
            <div class="name">
              <span>{{ $store.state.account.memberName }}님</span>
            </div>
          </div>
          <div class="email mb-0" :title="$store.state.account.email">{{ $store.state.account.email.split("@")[0] }}@ohmycompany</div>
        </RouterLink>
      </div>
    </div>
  </header>
</template>

<script>
import {defineComponent, onUnmounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import MemberPicture from "@/components/MemberPicture";

function Component(initialize) {
  this.name = "componentHeader";
  this.initialize = initialize;
}

export default defineComponent({
  components: {MemberPicture},
  mixins: [mixin],
  props: {
    collapsed: Boolean
  },
  setup() {
    const component = new Component(async () => {
      // setTimeout(() => {
      //   const investorCnt = 160640;
      //   const investorAmt = 17988843088;
      //   const increaseSpeed = 123;
      //   const increaseInvestorCnt = Math.round(investorCnt / increaseSpeed);
      //   const increaseInvestorAmt = Math.round(investorAmt / increaseSpeed);
      //
      //   state.interval = setInterval(() => {
      //     state.funding.investorCnt += increaseInvestorCnt;
      //     state.funding.investorAmt += increaseInvestorAmt;
      //
      //     if (state.funding.investorCnt >= investorCnt || state.funding.investorAmt >= investorAmt) {
      //       state.funding.investorCnt = investorCnt;
      //       state.funding.investorAmt = investorAmt;
      //       clearInterval(state.interval);
      //     }
      //   }, 10);
      // }, 250);
    });

    const state = reactive({
      interval: 0,
      funding: {
        investorCnt: 0,
        investorAmt: 0,
      }
    });

    onUnmounted(() => {
      clearInterval(state.interval);
    });

    return {component, state};
  },
});
</script>

<style lang="scss" scoped>
header {
  color: #fff;
  padding: 0 $px40 $px70 $px40;

  > .wrapper {
    font-weight: normal;
    padding: $px25 0;

    > .title {
      font-size: $px18;
      font-weight: 600;
      vertical-align: middle;
    }

    > .status {
      font-size: $px13;
      margin-top: $px7;
      color: $colorBackground;
    }

    > .side {
      position: absolute;
      top: $px21;
      right: 0;
      padding: 0 $px40;

      > .profile {
        display: inline-block;
        position: relative;
        padding-bottom: $px18;

        > .wrapper {
          display: inline-block;
          line-height: 1;
          font-size: 0;
          margin-left: $px8;
          max-width: $px60;

          > .badge {
            font-size: $px10;
            padding: $px3 $px5;
            border-radius: $px4 $px4 $px4 0;
            margin-left: $px-2;
          }

          .name {
            margin-top: $px5;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: $px13;
          }
        }

        .email {
          margin-top: $px2;
          font-size: $px11;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
        }
      }
    }
  }

  @media(max-width: 991px) {
    padding-left: $px15;
    padding-right: $px95;

    > .wrapper {
      padding-left: $px15;
      padding-right: $px15;

      > .side {
        padding-right: $px20;

        > .profile {
          padding-bottom: 0;
          text-align: center;

          > .wrapper {
            display: block;
            margin: 0;

            .badge {
              position: absolute;
              top: $px-9;
              left: $px-22;
              border-radius: $px4 $px4 0 $px4;
            }
          }

          .email {
            display: none;
          }
        }
      }
    }
  }

  @media(max-width: 575px) {
    > .wrapper > .side {
      top: $px33;
    }
  }
}
</style>