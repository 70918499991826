import store from "@/scripts/store";
import definitions from "@/scripts/definitions";

export default {
  getMenus() {
    return {
      mines: {
        title: "마이페이지",
        menu: [
          {
            name: "Dashboard",
            ico: "page.mypage.menu.dashboard.svg",
            title: "대시보드",
            desc: "",
            paths: ["/mypage"],
            valid: true,
          }, {
            name: "ArchiveApplication",
            ico: "page.mypage.menu.news.svg",
            title: "나의 신청서",
            desc: "",
            paths: ["/archive/applications"],
            valid: store.state.account.memberType === "C",
          }, {
            name: "Participant",
            ico: "page.mypage.menu.participant.svg",
            title: "펀딩 프로젝트",
            desc: "",
            paths: ["/mypage/participant/reward", "/mypage/participant/invest", "/mypage/participant/mock"],
            valid: true,
          }, {
            name: "Open",
            ico: "page.mypage.menu.open.svg",
            title: "진행 프로젝트",
            desc: "",
            paths: ["/mypage/open/reward", "/mypage/open/invest", "/mypage/open/mock"],
            valid: true,
          }, {
            name: "Interest",
            ico: "page.mypage.menu.interest.svg",
            title: "관심 프로젝트",
            desc: "",
            paths: ["/mypage/interest", "/mypage/interest/reward", "/mypage/interest/invest"],
            valid: true,
          }, {
            name: "Account",
            ico: "page.mypage.menu.account.svg",
            title: "계정 정보",
            desc: "",
            paths: ["/mypage/account", "/mypage/account/password", "/mypage/account/company", "/mypage/account/etc"],
            valid: true,
          }, {
            name: "News",
            ico: "page.mypage.menu.news.svg",
            title: "최근 소식",
            desc: "",
            paths: ["/mypage/news"],
            valid: true,
          }, {
            name: "Message",
            ico: "page.mypage.menu.message.svg",
            title: "메시지",
            desc: "",
            paths: ["/mypage/message/receive", "/mypage/message/send", "/mypage/message/keep"],
            valid: true,
          }, {
            name: "Follow",
            ico: "page.mypage.menu.follow.svg",
            title: "팔로우",
            desc: "",
            paths: ["/mypage/follow/followings", "/mypage/follow/followers"],
            valid: true,
          }, {
            name: "Review",
            ico: "page.mypage.menu.review.svg",
            title: "프로젝트 후기",
            desc: "",
            paths: ["/mypage/review/unreviewed", "/mypage/review/written"],
            valid: true,
          }, {
            name: "Inquiry",
            ico: "page.mypage.menu.inquiry.svg",
            title: "문의하기",
            desc: "",
            paths: ["/mypage/inquiry"],
            valid: true,
          }]
      },
      pays: {
        title: "결제",
        menu: [
          {
            name: "Payment",
            ico: "page.mypage.menu.payment.svg",
            title: "지불 정보",
            desc: "",
            paths: ["/mypage/payment"],
            valid: true,
          }, {
            name: "Point",
            ico: "page.mypage.menu.point.svg",
            title: "포인트",
            desc: "",
            paths: ["/mypage/point"],
            valid: true,
          }, {
            name: "Coupon",
            ico: "page.mypage.menu.coupon.svg",
            title: "쿠폰",
            desc: "",
            paths: ["/mypage/coupon"],
            valid: true,
          }, {
            name: "Investor",
            ico: "page.mypage.menu.investor.svg",
            title: "투자자 인증",
            desc: "",
            paths: ["/mypage/investor"],
            valid: true,
          }, {
            name: "InvestorAccountInfo",
            ico: "page.mypage.menu.investor-account.svg",
            title: "투자 계좌",
            desc: "",
            paths: ["/mypage/investor-account-info"],
            valid: store.state.investor.investorAuth === "INVESTOR_STATUS_COMPLETE",
          }],
      },
      etc: {
        title: "기타",
        menu: [
          {
            name: "Guide",
            ico: "page.mypage.menu.guide.svg",
            title: "이용가이드",
            desc: "",
            paths: [`${definitions.urls.generalGuide}`],
            valid: true,
          }, {
            name: "CompatibilityTest",
            ico: "page.mypage.menu.compatibility-test.svg",
            title: "투자자 적합성",
            desc: "",
            paths: [null],
            valid: true,
            click: () => store.commit("openModal", {
              name: "CompatibilityTest",
              callback: "refresh",
              preventClose: true,
              routerReplace: true
            }),
          }, {
            name: "Logout",
            ico: "page.mypage.menu.logout.svg",
            title: "로그아웃",
            desc: "",
            paths: [null],
            valid: true,
            click: () => store.dispatch("logoutAccount", {home: true}),
          }],
      }
    };
  }
};