import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import VueCompositionAPI from "@vue/composition-api";
import store from "@/scripts/store";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import definitions from "@/scripts/definitions";
import env from "@/scripts/env";
import track from "@/scripts/track";
import "@/styles/_common.scss";
import http from "@/scripts/http";

Vue.use(VueRouter);
Vue.use(VueCompositionAPI);

Vue.prototype.$lib = lib;
Vue.prototype.$definitions = definitions;
Vue.prototype.$env = env;

Vue.config.productionTip = false;

track.initialize();

// 전역 객체 프로퍼티 세팅(window.global.properties)
((w) => {
  if (!w.global?.properties) {
    console.error("전역 프로퍼티 값이 없습니다.");
    return;
  }

  const noCdn = env.location !== "prd" && env.development;

  definitions.imp = w.global.properties.imp;
  definitions.sns.properties = w.global.properties.sns;
  definitions.urls.imgCdn = noCdn ? "" : w.global.properties.cdn.imageUrl;
  definitions.urls.imgCdnMarkSuffix = noCdn ? "" : w.global.properties.cdn.imageUrlMarkSuffix;
  definitions.urls.account = w.global.properties.account.url;
  definitions.codes = w.global.codes;
  definitions.keywords = w.global.keywords;
  definitions.limits = w.global.properties.limits;
})(window);

const visitorKey = "visitor";

if (window.sessionStorage.getItem(visitorKey) === null) {
  const value = Number(lib.getDateFormat(new window.Date(), "yyyyMMddHHmmss")).toString(16);
  const codeLen = 20;
  const ranLen = codeLen - value.length;
  const code = store.getters.generateRandomId(ranLen) + value;
  window.sessionStorage.setItem(visitorKey, code);
  http.post("/api/visits", {code}).then();
}

const vue = new Vue({
  render: h => h(App),
  store, router,
});

router.onReady(() => {
  vue.$mount("#app");
});