<template>
  <div class="card" :class="{skeleton}" :data-page="page" :style="{ height: height ? `${height}px` : '' }">
    <div class="inner" :class="alignment">
      <Anchor :href="`/channel/${builderSeq}`" class="profile no-underline w-100" title="진행자 채널로 이동" v-if="builderName">
        <MemberPicture :memberSeq="builderSeq" :size="`${profileSize || '28'}`"/>
        <div class="font-xs ellipsis">
          <span>{{ builderName }}</span>
        </div>
      </Anchor>
      <div class="cover">
        <Anchor :href="computedLink" class="thumb pointer" :click="click" :style="thumbRatioToSquare && {paddingTop: '100%'}">
          <div class="img" :style="{backgroundImage: `url('${computedThumbnailUrl}')`, backgroundPosition: mainImagePosition || 'center'}"></div>
        </Anchor>
        <button class="interest pointer img" title="관심 프로젝트"
                :style="{ opacity: state.toggling ? 0 : 1, backgroundImage: `url(/assets/ico/component.combined-card.heart${computedInterested ? '.fill' : ''}.svg)` }"
                :disabled="!$store.state.interests.loaded"
                @click="toggleInterest({projectSeq, projectType, projectName})"
                v-if="['invest','reward'].includes(projectType) && !hideInterest && (projectType === 'invest' ? fundingType !== '02' : true)"></button>
      </div>
      <div class="panel">
        <span class="color-secondary font-xs d-lg-none d-inline-block mb-1 builder-name" v-if="alignment=== 'mobile-horizontal' && builderName">{{ builderName }}</span>
        <div class="above" :style="{ height: computedAboveHeight }">
          <div class="title">
            <Anchor :href="link" class="pointer no-underline">{{ projectName }}</Anchor>
          </div>
          <div class="desc" v-if="simpleText !== undefined">
            <span>{{ simpleText }}</span>
          </div>
        </div>
        <div class="tags" v-if="projectType === 'invest'">
          <span class="badge badge-point-soft" v-if="stockTypeText">{{ stockTypeText }}</span>
          <span class="badge badge-primary-soft" v-if="fundingType === '02'">사모</span>
          <span class="badge badge-secondary-soft" v-if="rewardFlag === 'Y'">리워드</span>
          <span class="badge badge-danger-soft" v-if="incomeDeductionFlag === 'Y'">소득공제</span>
        </div>
        <div class="bottom">
          <div class="font-sm" v-if="hideState">
            <span>모금 현황 </span>
            <span class="color-point">비공개 프로젝트</span>
            <span>입니다.</span>
          </div>
          <div class="actions" v-else-if="computedPrivateEquityBtnVisible">
            <button class="btn btn-light" @click="privateEquityEvent('finished')" v-if="privateEquityApprovedCnt >= 49">신청 마감</button>
            <button class="btn btn-light" @click="privateEquityEvent('waiting')" v-else-if="privateEquityParticipantStatusCode === 0">승인 대기 중</button>
            <button class="btn btn-point" @click="privateEquityEvent('reApply')" v-else-if="privateEquityParticipantStatusCode === 2">펀드 참여 재신청</button>
            <button class="btn btn-point" @click="privateEquityEvent('apply')" v-else>
              <span>펀드 참여 신청</span>
              <span class="fa fa-long-arrow-right pl-2"></span>
            </button>
          </div>
          <template v-else-if="percent !== undefined">
            <div class="graph" v-if="page !== 'home' && progressOrder === 1">
              <div
                  class="progress-bar bg-point"
                  role="progressbar"
                  :style="{ width: (percent > 100 ? 100 : percent) + '%' }"
                  aria-label="진행 표시줄"
                  :aria-valuenow="percent"
                  aria-valuemin="0"
                  aria-valuemax="100"
              ></div>
            </div>
            <div class="summary">
              <div class="left">
                <b class="color-point font-md">{{ $lib.getNumberFormat(percent) }}<span class="sign">%</span></b>
                <span class="font-sm ml-2 font-weight-light" v-if="amount !== undefined">{{ $lib.getNumberFormat(amount) }}{{ computedCurrencyUnit }}</span>
              </div>
              <div class="right">
                <span class="font-xs" v-if="page !== 'home' && progressOrder !== 1">모집 {{ successFailName }}</span>
                <template v-else-if="dday !== undefined">
                  <span class="font-xs" v-if="dday === 0">오늘 마감</span>
                  <span class="font-xs" v-else>{{ dday ? $lib.getNumberFormat(dday) + "일 남음" : "마감" }}</span>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import MemberPicture from "./MemberPicture";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Anchor from "@/components/Anchor";
import redirectCallback from "@/scripts/redirectCallback";
import RewardCardBottom from "@/components/RewardCardBottom.vue";

function Component(initialize) {
  this.name = "componentCard";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {RewardCardBottom, Anchor, MemberPicture},
  props: {
    page: String,
    projectSeq: Number,
    projectType: String,
    amount: Number,
    targetAmt: Number,
    percent: Number,
    dday: Number,
    progressOrder: Number,
    thumbFilePath: String,
    projectName: String,
    link: String,
    builderSeq: String,
    builderName: String,
    simpleText: String,
    projectCate: String,
    fundingType: String,
    stockTypeText: String,
    rewardFlag: String,
    incomeDeductionFlag: String,
    count: Number,
    height: Number,
    successFailName: String,
    skeleton: Boolean,
    hideInterest: Boolean,
    noProfile: Boolean,
    badge: String,
    period: String,
    regState: String,
    createDate: String,
    partnership: String,
    remove: Function,
    privateEquityApprovedCnt: Number,
    privateEquityParticipantStatusCode: Number,
    profileSize: String,
    hideState: Boolean,
    applyCnt: Number,
    mainImagePosition: String,
    contestSeq: String,
    currencyUnit: String,
    thumbRatioToSquare: Boolean,
    alignment: String,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({
      toggling: false
    });

    const computedLink = computed(() => {
      return computedPrivateEquityBtnVisible.value || computedPrivateEquityInaccessible.value ? undefined : props.link || undefined;
    });

    const computedCurrencyUnit = computed(() => {
      return props.currencyUnit || "원";
    });

    const computedPrivateEquityBtnVisible = computed(() => {
      return props.projectType === "invest" && props.fundingType === "02" && props.progressOrder === 1 && props.privateEquityParticipantStatusCode !== 1;
    });

    const computedPrivateEquityInaccessible = computed(() => {
      return props.projectType === "invest" && props.fundingType === "02" && (!["MEMBER_MID_ADMIN", "MEMBER_TOP_ADMIN"].includes(store.state.account.memberAuth) && props.privateEquityParticipantStatusCode !== 1);
    });

    const computedThumbnailUrl = computed(() => {
      return store.getters.thumbnailUrl(props.thumbFilePath);
    });

    const computedInterested = computed(() => {
      return store.state.interests.sequences[props.projectType] && store.state.interests.sequences[props.projectType].includes(props.projectSeq);
    });

    const computedAboveHeight = computed(() => {
      let value = 0;

      const add = (v) => {
        value += v;
      };

      (props.page === "search" && props.projectType === "invest") ? add(36) : add(40);
      props.simpleText !== undefined && add(27);

      return value + "px";
    });

    const click = () => {
      props.projectType === "invest" && props.fundingType === "02" && !store.state.account.loggedIn &&
      store.dispatch("goLoginPage");
    };

    const toggleInterest = ({projectSeq, projectType, projectName}) => {
      const args = {projectSeq, projectType, projectName};

      if (!store.state.account.loggedIn) {
        return redirectCallback.setAndLogin("toggleProjectInterest", args);
      }

      state.toggling = true;
      redirectCallback.run("toggleProjectInterest", {
        ...args,
        onSuccess() {
          state.toggling = false;
        },
        onFail() {
          state.toggling = false;
        }
      });
    };

    const privateEquityEvent = (status) => {
      if (status === "apply") {
        if (!store.state.account.loggedIn) {
          store.dispatch("goLoginPage");
        } else {
          apply();
        }
      } else if (status === "finished") {
        store.commit("setSwingMessage", "이미 참가 모집이 완료되었습니다.");
      } else if (status === "waiting") {
        store.commit("setSwingMessage", "담당자 승인 대기중입니다.");
      } else if (status === "reApply") {
        apply();
      }
    };

    const apply = () => {
      store.commit("openModal", {
        name: "PrivateEquityApply",
        params: {projectSeq: props.projectSeq},
        callback: "refresh"
      });
    };

    return {
      component
      , state
      , computedLink
      , computedCurrencyUnit
      , computedPrivateEquityBtnVisible
      , computedAboveHeight
      , computedThumbnailUrl
      , computedInterested
      , click
      , toggleInterest
      , privateEquityEvent
      , apply
    };
  },
});
</script>

<style lang="scss" scoped>
.card {
  border: none;

  .inner {
    position: relative;

    .profile {
      display: flex;
      gap: $px8;
      align-items: center;
      margin-bottom: $px10;
      width: fit-content;

      > .img {
        flex-shrink: 0;
      }
    }

    .cover {
      position: relative;

      > .thumb {
        display: block;
        padding-top: $ratio43Percent;
        position: relative;
        overflow: hidden;
        transition: filter 0.18s;
        border-radius: $thumbBorderRadius;

        > .img {
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-position: 50% 50%;
          background-color: #eee;
          width: 100%;
          height: 100%;
          display: inline-block;
          transition: transform 0.18s;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      > .interest {
        position: absolute;
        top: $px8;
        right: $px8;
        width: $px16;
        height: $px16;
        padding: $px16;
        border: 0;
        background-size: $px16 $px16;
        background-position: 50%;
        background-color: rgba(#000000, 0.3);
        border-radius: 50%;
      }
    }

    .panel {
      padding: $px12 $px8 0 $px8;

      .above {
        > .title {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-height: 1.2;
        }

        .desc {
          font-size: $px13;
          color: $colorTextSecondary;
          margin-top: $px8;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          line-height: 1.2;
        }
      }

      .tags {
        margin-top: $px6;

        > .badge {
          padding: $px8 $px16;
          border-radius: $px11;

          &:not(:last-child) {
            margin-right: $px4;
          }
        }
      }

      .bottom {
        height: $px40;
        margin-top: $px10;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: $px8;

        .graph {
          background-color: #e1e1e1;
          border-radius: $px2;
          flex-shrink: 0;
          height: $px3;
          position: relative;
          width: 100%;

          > div {
            height: 100%;
            min-width: 2%;
          }
        }

        .summary {
          display: flex;
          gap: $px16;
          justify-content: space-between;
          align-items: center;

          .left {
            white-space: nowrap;
          }

          .right {
            background-color: #eee;
            border-radius: $px2;
            color: $colorTextSecondary;
            font-size: $px14;
            line-height: 1;
            padding: $px4;
            white-space: nowrap;
          }
        }
      }

      .actions {
        text-align: center;

        > .btn {
          width: fit-content;
          border-radius: $px100;
          font-size: $px14;
        }
      }
    }
  }

  &[data-page="home"] .inner .panel .bottom {
    height: auto;
  }

  &[data-page="search"] .inner .panel {
    .tags {
      > .badge {
        border-radius: $px8;
        padding: $px6 $px10;
      }
    }
  }

  &.skeleton {
    .inner {
      .profile {
        > .img, div {
          @include skeleton
        }
      }

      .cover {
        > .thumb {
          @include skeleton;

          > .img {
            visibility: hidden;
          }
        }

        > .interest {
          display: none;
        }
      }
    }

    .panel {
      > .builder-name {
        @include skeleton;
      }

      .above {
        > .title, .desc {
          @include skeleton;
        }
      }

      .tags {
        .badge {
          @include skeleton;
        }
      }

      .bottom {
        display: none;

        .graph > div {
          @include skeleton;
        }

        .summary {
          .left, .right {
            b, span {
              @include skeleton;
            }
          }
        }
      }

      .actions {
        > .btn {
          @include skeleton;
        }
      }
    }
  }

  &:not(.skeleton) {
    .inner .cover .thumb > .img:hover {
      transform: scale(1.1);
    }
  }

  @media(max-width: 991px) {
    .inner {
      &.mobile-horizontal {
        display: flex;
        align-items: center;
        gap: $px16;

        .profile {
          display: none;
        }

        .cover {
          flex-shrink: 0;
          width: $px128;

          .thumb {
            border-radius: $px10;
          }
        }

        .panel {
          flex-grow: 1;
          padding-top: 0;
          padding-left: 5px;

          .bottom {
            margin-top: $px8;
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    .inner .panel {
      .above {
        height: auto !important;
      }

      .tags {
        margin-top: $px10;
      }

      .bottom {
        height: auto;
      }
    }
  }
}
</style>