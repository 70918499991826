<template>
  <div class="review-tag-bar" :class="{skeleton: (!state.loaded || skeleton), view: state.view, [classType] : classType}" v-if="state.stats.some(t => t.value) || !state.loaded">
    <div v-if="classType === 'detail'" class="wrapper">
      <span class="emoji" :style="{backgroundImage: `url('/assets/ico/component.reviewtagbar.pushpin.png')`}"></span>
      <div class="text">
        <span>프로젝트 후기 목록</span>
        <span class="desc">프로젝트 후기의 키워드 통계입니다</span>
      </div>
    </div>
    <div class="title" v-else-if="classType === 'maker'">
      <span>전체 프로젝트 후기</span>
      <Anchor :href="`/channel/${builderSeq}?tabs=reviews`" class="sub-text">전체보기</Anchor>
    </div>
    <div class="stats" :class="{overflow: overflow}">
      <template v-for="(t, idx) in state.stats">
        <div class="tag" v-if="t.value || !state.loaded" :key="idx">
          <div>
            <span class="progress" :style="{width: `${Math.floor((t.value / state.reviewTotal) * 100)}%`, opacity: `${Math.floor((t.value / state.reviewTotal) * 50)}%`}"></span>
            <span class="name">
              <span class="ico" :style="{backgroundImage: `url('/assets/ico/component.reviewtagbar.${t.emoji}.png')`}"></span> <span>{{ t.title }}</span>
            </span>
          </div>
          <span class="percent">{{ t.value }}</span>
        </div>
      </template>
    </div>
    <div class="view-more" @click="viewMore()" v-if="state.overflow && state.loaded && !skeleton">
      <span v-if="!state.view"><i class="fa fa-angle-down"></i></span>
      <span v-else><i class="fa fa-angle-up"></i></span>
    </div>
  </div>
</template>

<script>import mixin from "@/scripts/mixin";
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import Anchor from "@/components/Anchor";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "ComponentReviewTagBar";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  props: {
    builderSeq: String,
    classType: String,
    url: String,
    overflow: Boolean,
    skeleton: Boolean,
  },
  setup(props) {
    const component = new Component(() => {
      getStats();
    });

    const state = reactive({
      loaded: false,
      view: false,
      overflow: false,
      stats: [],
      reviewTotal: null,
    });

    const getStats = () => {
      state.loaded = false;

      http.get(props.url).then(({data}) => {
        state.loaded = true;
        state.stats = definitions.keywords.reviews;
        state.stats = state.stats.filter(s => s.name !== "none");
        state.reviewTotal = data.body.reviewTotal;

        for (let i in state.stats) {
          state.stats[i].value = data.body.tags[state.stats[i].name];
        }

        sortTags();
      });
    };

    const sortTags = () => {
      state.stats = state.stats.sort((a, b) => {
        return b.value - a.value || a.title.localeCompare(b.title);
      });

      if (!state.stats.some(t => t.value)) {
        return;
      }

      let count = 0;
      state.stats.forEach(t => t.value ? ++count : count);

      if (count > 2 && props.overflow) {
        state.overflow = true;
      } else if (count > 3 && props.classType === "maker") {
        state.stats = state.stats.slice(0, 3);
      }
    };

    const viewMore = () => {
      if (state.view) {
        return state.view = false;
      } else {
        return state.view = true;
      }
    };

    return {component, state, viewMore};
  }
});
</script>

<style lang="scss" scoped>
.review-tag-bar {
  font-size: $px13;

  .wrapper {
    font-size: $px16;
    margin-bottom: $px15;

    span {
      vertical-align: top;

      &.emoji {
        margin-right: $px10;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        top: $px4;
        width: $px20;
        height: $px20;
        display: inline-block;
      }
    }

    .text {
      display: inline-block;

      .desc {
        display: block;
        font-size: $px12;
        color: #aaa;
      }
    }
  }

  .title {
    font-size: $px14;
    font-weight: 500;
    margin-bottom: $px13;
    margin-top: $px20;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > span, > a {
      vertical-align: bottom;
    }

    > .sub-text {
      font-size: $px10;
      color: $colorSecondary;
    }
  }

  .stats {
    .tag {
      background-color: $colorBackground;
      border-radius: $px4;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      padding: $px10;
      position: relative;
      white-space: nowrap;

      .name, > span {
        position: relative;

        .ico {
          background-size: 100% 100%;
          background-position: center;
          background-repeat: no-repeat;
          display: inline-block;
          width: $px16;
          height: $px16;
          vertical-align: middle;
          margin-right: $px4;
        }
      }

      .progress {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $colorPurple;
        height: 100%;
        border-radius: 0;
      }

      .char {
        font-size: $px12;
        margin-left: $px2;
      }

      &:not(:last-child) {
        margin-bottom: $px10;
      }
    }
  }

  .view-more {
    display: none;
    width: 100%;
    padding: $px30 $px15 0 $px15;
    text-align: center;

    > span i {
      font-size: $px26;
      font-weight: 500;
    }
  }

  &.detail {
    margin-bottom: $px30;
  }

  &.maker {
    .stats .tag {
      border-radius: $px100;
      font-size: $px10;
      padding: $px6 $px10;

      .name {
        .ico {
          width: $px12;
          height: $px12;
        }
      }

      .percent {
        font-size: $px11;
        font-weight: 500;

        .char {
          font-size: $px10;
        }
      }
    }
  }

  &.skeleton {
    .wrapper {
      .emoji, .text > span {
        @include skeleton;
      }
    }

    .title > span, .title > a {
      @include skeleton;
    }

    .stats .tag, .stats .tag span, .progress {
      @include skeleton();
    }
  }

  @media (max-width: 991px) {
    .overflow {
      max-height: $px145;
      overflow: hidden;
    }

    .view-more {
      display: block;
      position: relative;
      cursor: pointer;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-100%);
        height: 100%;
        width: 100%;
        content: "";
        display: block;
        background: rgb(255, 255, 255);
        background: linear-gradient(360deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 80%);
      }
    }

    &.view {
      .overflow {
        max-height: initial;
      }

      .view-more::before {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .overflow {
      max-height: $px90;
    }
  }
}
</style>