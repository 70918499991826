<template>
  <header :class="[...$store.state.classes.header, state.toggle ? 'opened' : '']">
    <div :id="`${component.name}Banners`" class="banners no-translate" :style="{ height: state.banner.height + 'px', marginTop: state.banner.marginTop + 'px' }" v-if="state.banner.list.length">
      <div class="handle" :class="{transition: state.banner.handle.transition }" :style="{ marginTop: state.banner.handle.marginTop + 'px' }"></div>
      <Anchor :href="b.bannerUrl" class="banner" v-for="(b, idx) in state.banner.list" :key="idx" :style="getBannerStyle(idx)">
        <img :src="b.imagePath + b.imageSaveName" :alt="`상단 배너`" v-if="b.imagePath"/>
        <span class="text">{{ b.title }}</span>
      </Anchor>
      <span class="remove" @click.prevent="remove()" :style="{ color: getBannerStyle(0).color }">&times;</span>
    </div>
    <div class="wrapper">
      <!-- desktop start -->
      <div class="desktop">
        <div class="container">
          <div class="header">
            <div class="brand">
              <router-link to="/" @click.native="root()">
                <img src="/assets/img/common.logo.svg" alt="로고"/>
              </router-link>
            </div>
            <nav class="nav font-md">
              <ul class="tight">
                <li>
                  <router-link :class="{ 'color-point': $route.path.startsWith('/reward') }" to="/reward/list">후원하기</router-link>
                </li>
                <li>
                  <router-link :class="{ 'color-point': $route.path.startsWith('/invest') }" to="/invest/list">투자하기</router-link>
                </li>
                <li>
                  <router-link :class="{ 'color-point': $route.path.startsWith('/reservation') }" to="/reservation/list">오픈예정</router-link>
                </li>
                <li>
                  <a :href="$definitions.urls.generalGuide" target="_blank" rel="noopener noreferrer">
                    <span>가이드</span>
                  </a>
                </li>
              </ul>
              <div class="right">
                <a class="admin pointer" href="/new-admin" target="_blank" aria-label="관리자 페이지" title="관리자 페이지" v-if=" ['MEMBER_MID_ADMIN', 'MEMBER_TOP_ADMIN'].includes($store.state.account.memberAuth)">
                  <span class="img" style="background-image: url('/assets/ico/common.setting.svg')"></span>
                </a>
                <button type="button" class="search" aria-label="프로젝트 검색" title="프로젝트 검색" @click="$store.commit('openModal', {name: 'Search'})">
                  <img src="/assets/ico/common.search.svg" alt="검색 아이콘">
                </button>
                <router-link to="/mypage" class="picture pointer no-underline" title="마이페이지" v-if="$store.state.account.loggedIn">
                  <MemberPicture size="34" :memberSeq="$store.state.account.memberSeq"/>
                  <span class="bubble">My</span>
                </router-link>
                <a :href="$store.getters.loginPageUrl()" class="no-underline pointer font-sm" v-else-if="$store.state.account.checked">로그인</a>
                <router-link to="/apply/intro" class="btn btn-point font-sm">펀딩 신청하기</router-link>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <!-- desktop end -->
      <div class="mobile" :id="`${component.name}MobileSideNav`">
        <div class="container">
          <div class="header">
            <router-link to="/" class="logo" @click.native="root()">
              <span class="img" style="background-image: url(/assets/img/common.logo.svg)"></span>
            </router-link>
            <div class="actions">
              <button type="button" aria-label="프로젝트 검색" title="프로젝트 검색" @click="$store.commit('openModal', {name: 'Search'})">
                <img src="/assets/ico/common.search.svg" alt="검색 아이콘">
              </button>
              <button type="button" aria-label="메뉴" title="메뉴" @click="toggle()">
                <img src="/assets/ico/layouts.ground.header.toggle.svg" alt="메뉴 아이콘">
              </button>
            </div>
          </div>
          <nav class="nav" v-show="state.toggle">
            <div class="account">
              <template v-if="$store.state.account.loggedIn">
                <div class="profile">
                  <router-link to="/mypage/account" class="picture pointer no-underline" title="마이페이지" @click.native="toggle(false)">
                    <MemberPicture :memberSeq="$store.state.account.memberSeq" size="40" :squircle="true"/>
                  </router-link>
                  <div class="info">
                    <span>{{ $store.state.account.memberName }}</span>
                    <router-link to="/mypage/investor" class="no-underline d-block font-sm" @click.native="toggle(false)">
                      <span class="color-secondary" v-if="$store.state.investor.investorAuth === 'INVESTOR_STATUS_COMPLETE'">{{ $store.state.investor.investorGrade }}</span>
                      <span class="color-anchor" v-else>투자자 인증하러 가기</span>
                    </router-link>
                  </div>
                </div>
                <router-link to="/mypage" class="btn pointer no-underline font-sm" @click.native="toggle(false)">
                  <b>마이페이지</b>
                </router-link>
              </template>
              <div class="d-flex align-items-center" v-else>
                <MemberPicture size="40" :squircle="true"/>
                <div class="ml-4 actions">
                  <span class="color-secondary font-sm d-block pb-1">1분 만에 빠른 회원가입</span>
                  <a :href="$store.getters.loginPageUrl()" class="color-anchor mr-2">로그인</a>
                  <a :href="$store.getters.loginPageUrl('join')" class="pointer color-anchor">회원가입</a>
                </div>
              </div>
            </div>
            <div class="menus" v-for="c in computedMenus" :key="`mobileParentMenu${c.name}`">
              <div class="title"><span>{{ c.title }}</span></div>
              <ul class="tight">
                <li v-for="m in c.list" :key="`mobileChildMenu${m.name}`" :class="{'color-point': $route.path.startsWith(`${m.active}`) }">
                  <Anchor :href="m.link" @click.native="toggle(false)" class="no-underline">
                    <span class="img" :style="{backgroundImage: `url(${m.ico})`}"></span>
                    <span>{{ m.title }}</span>
                  </Anchor>
                </li>
              </ul>
            </div>
            <!--            <div class="menus">-->
            <!--              <div class="title"><span>둘러보기</span></div>-->
            <!--              <ul class="tight">-->
            <!--                <li :class="{ 'color-point': $route.path.startsWith('/reward') }">-->
            <!--                  <router-link to="/reward/list" @click.native="toggle(false)">후원하기</router-link>-->
            <!--                </li>-->
            <!--                <li :class="{ 'color-point': $route.path.startsWith('/invest') }">-->
            <!--                  <router-link to="/invest/list" @click.native="toggle(false)">투자하기</router-link>-->
            <!--                </li>-->
            <!--                <li :class="{ 'color-point': $route.path.startsWith('/reservation') }">-->
            <!--                  <router-link to="/reservation/list" @click.native="toggle(false)">오픈예정</router-link>-->
            <!--                </li>-->
            <!--                <li :class="{ 'color-point': $route.path.startsWith('/partnership') }">-->
            <!--                  <router-link to="/partnership/partnerlist" @click.native="toggle(false)">파트너십</router-link>-->
            <!--                </li>-->
            <!--                <li :class="{ 'color-point': $route.path.startsWith('/apply') }">-->
            <!--                  <router-link to="/apply/intro" @click.native="toggle(false)">프로젝트 신청</router-link>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </div>-->
            <!--            <div class="menus">-->
            <!--              <div class="title"><span>안내</span></div>-->
            <!--              <ul class="tight font-sm">-->
            <!--                <li :class="{ 'color-point': $route.path.startsWith('/community/notice') }">-->
            <!--                  <router-link to="/community/notice" @click.native="toggle(false)">공지사항</router-link>-->
            <!--                </li>-->
            <!--                <li>-->
            <!--                  <a :href="$definitions.urls.companyIntro" target="_blank" rel="noopener noreferrer">회사 소개</a>-->
            <!--                </li>-->
            <!--                <li>-->
            <!--                  <a :href="$definitions.urls.generalGuide" target="_blank" rel="noopener noreferrer">가이드</a>-->
            <!--                </li>-->
            <!--                <li :class="{ 'color-point': $route.path.startsWith('/policy/terms') }">-->
            <!--                  <router-link to="/policy/terms" @click.native="toggle(false)">이용약관</router-link>-->
            <!--                </li>-->
            <!--                <li :class="{ 'color-point': $route.path.startsWith('/policy/rewardPrivacy') }">-->
            <!--                  <router-link to="/policy/rewardPrivacy" @click.native="toggle(false)">개인정보보호정책</router-link>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </div>-->
            <div class="actions w-100 text-center">
              <button class="btn btn-default font-sm" v-if="$store.state.account.loggedIn" @click="logout()">로그아웃</button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {computed, defineComponent, nextTick, onMounted, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import router from "@/scripts/router";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import Anchor from "@/components/Anchor";
import MemberPicture from "@/components/MemberPicture.vue";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "componentHeader";
  this.initialize = initialize;
}

export default defineComponent({
  components: {MemberPicture, Anchor},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      http.get("/api/main/banners/top", undefined, {cache: true}).then(({data}) => {
        if (!data?.body?.length) {
          state.banner.height = 0;
          return;
        }

        state.banner.list = data.body;

        nextTick(() => {
          setTimeout(() => {
            state.banner.marginTop = 0;
          }, 250);
        });

        if (state.banner.list.length > 1) {
          const move = (idx, transition) => {
            state.banner.handle.transition = transition;
            state.banner.handle.marginTop = idx * state.banner.height * -1;
          };

          // 배너 롤링 반복
          state.banner.interval = setInterval(() => {
            move(1, true);

            nextTick(() => {
              setTimeout(() => {
                state.banner.list.push(state.banner.list.splice(0, 1)[0]);
                move(0, false);
              }, state.banner.handle.transitionTime);
            });
          }, state.banner.intervalTime);
        }
      });

      lib.isMobile() && http.get("/api/main/contests").then(({data}) => {
        state.contests = data.body;
      });
    });

    const state = reactive({
      path: "",
      toggle: false,
      contests: [],
      banner: {
        list: [],
        interval: 0,
        height: 50,
        marginTop: -50,
        intervalTime: 2500,
        handle: {
          transition: true,
          transitionTime: 500,
          marginTop: 0
        }
      },
      stickStyle: {
        display: "",
        width: "0",
        left: "0"
      }
    });

    const computedMenus = computed(() => {
      return [{
        title: "둘러보기",
        name: "main",
        list: [
          {
            name: "reward",
            title: "후원하기",
            link: "/reward/list",
            ico: "/assets/ico/layouts.ground.header.reward.svg",
            active: "/reward",
          },
          {
            name: "invest",
            title: "투자하기",
            link: "/invest/list",
            ico: "/assets/ico/layouts.ground.header.invest.svg",
            active: "/invest",
          },
          {
            name: "reservation",
            title: "오픈예정",
            link: "/reservation/list",
            ico: "/assets/ico/layouts.ground.header.reservation.svg",
            active: "/reservation",
          },
          {
            name: "partnership",
            title: "파트너십",
            link: "/partnership/partnerlist",
            ico: "/assets/ico/layouts.ground.header.partnership.svg",
            active: "/partnership",
          },
          {
            name: "apply",
            title: "프로젝트신청",
            link: "/apply/intro",
            ico: "/assets/ico/layouts.ground.header.apply.svg",
            active: "/apply",
          },
        ],
      }, {
        title: "안내",
        name: "guide",
        list: [
          {
            name: "notice",
            title: "공지사항",
            link: "/community/notice",
            ico: "/assets/ico/layouts.ground.header.notice.svg",
            active: "/community",
          },
          {
            name: "introduce",
            title: "회사소개",
            link: definitions.urls.companyIntro,
            ico: "/assets/ico/layouts.ground.header.introduce.svg",
            active: null,
          },
          {
            name: "guide",
            title: "가이드",
            link: definitions.urls.generalGuide,
            ico: "/assets/ico/layouts.ground.header.guide.svg",
            active: null,
          },
          {
            name: "terms",
            title: "이용약관",
            link: "/policy/terms",
            ico: "/assets/ico/layouts.ground.header.terms.svg",
            active: "/policy/terms",
          },
          {
            name: "rewardPrivacy",
            title: "개인정보보호정책",
            link: "/policy/rewardPrivacy",
            ico: "/assets/ico/layouts.ground.header.privacy.svg",
            active: "/policy/rewardPrivacy",
          },
        ],
      }
      ];
    });

    const root = () => {
      if (router.app.$route.path === "/") {
        window.scrollTo({top: 0, behavior: "smooth"});
      }

      toggle(false);
    };

    const toggle = (val) => {
      if (val !== undefined) {
        state.toggle = val;
      } else {
        state.toggle = !state.toggle;
      }

      if (state.toggle) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");

        const mobileSideNav = document.getElementById(`${component.name}MobileSideNav`);
        mobileSideNav && (mobileSideNav.scrollTop = 0);
      }
    };

    const search = (keyword) => {
      toggle(false);
      store.commit("openModal", {
        name: "Search",
        params: {keyword}
      });
    };

    const remove = () => {
      const $banners = document.getElementById(`${component.name}Banners`);
      clearInterval(state.banner.interval);

      if (!$banners) {
        return;
      }

      $banners.style.marginTop = (state.banner.height * -1) + "px";

      setTimeout(() => {
        state.banner.list = [];
        nextTick(() => {
          window.scrollTo(window.scrollX, window.scrollY + 1);
        });
      }, 250);
    };

    const moveStick = () => {
      const nav = document.querySelector(".nav-opened");
      const span = nav?.querySelector(".nav-opened > ul > li.active > a > span");

      if (!nav || !span) {
        state.stickStyle.display = "none";
        return;
      }

      const navRect = nav.getBoundingClientRect();
      const spanRect = span.getBoundingClientRect();

      state.stickStyle.left = (spanRect.x - navRect.x) + "px";
      state.stickStyle.width = spanRect.width + "px";
      state.stickStyle.display = "inline-block";
    };

    const getBannerStyle = (idx) => {
      const banner = state.banner.list[idx];

      return {
        background: banner.bgColor ? "#" + banner.bgColor.replace("#", "") : "",
        color: banner.titleColor ? "#" + banner.titleColor.replace("#", "") : "",
      };
    };

    const getPartnershipLink = (contest) => {
      switch (contest.contestType) {
        case "E":
        case "P":
          return contest.rewardProjectCount < 1
              ? "/partnership/" + contest.contestSeq + "/invest"
              : "/partnership/" + contest.contestSeq + "/reward";

        case "S":
          return "/partnership/" + contest.contestSeq + "/mock";
      }

      return "";
    };

    const runMoveStickTwice = () => {
      setTimeout(moveStick);
      setTimeout(moveStick, 1000);
    };

    const logout = () => {
      toggle(false);
      store.dispatch("logoutAccount", {home: true});
    };

    const openSearch = () => {
      toggle(false);
      store.commit("openModal", {name: "Search"});
    };

    watch(() => router.app.$route.path, () => {
      window.innerWidth <= 991 && nextTick(runMoveStickTwice);
    });

    onMounted(() => {
      runMoveStickTwice();
      clearInterval(state.banner.interval);
    });

    return {
      component
      , state
      , computedMenus
      , root
      , toggle
      , search
      , remove
      , getBannerStyle
      , getPartnershipLink
      , logout
      , openSearch
    };
  },
});
</script>

<style lang="scss" scoped>
header {
  box-shadow: 0 0 $px10 0 rgba(4, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;

  > .banners {
    background: #fff;
    text-align: center;
    overflow: hidden;
    position: relative;
    transition: margin-top 0.5s;
    z-index: 20;

    .handle {
      &.transition {
        transition: margin-top 0.5s;
      }
    }

    > .banner {
      height: 100%;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 0 $px50;
      text-decoration: none;
      font-weight: 600;

      > img {
        height: 100%;
        margin-right: $px12;
      }

      > .text {
        font-size: $px16;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    > .remove {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: $px50;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 0.7;
      }
    }

    &:after {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: $px1;
      background: #eee;
    }
  }

  > .wrapper {
    position: relative;
    padding: $px22 0;
    z-index: 10;
    background: #fff;

    .desktop {
      .container {
        .header {
          display: flex;
          align-items: center;
          gap: $px24;

          .brand {
            flex-shrink: 0;
            margin-bottom: $px8;

            > a {
              cursor: pointer;
              text-decoration: none;
              display: inline-block;

              img {
                width: $px187;
                height: $px33;
              }
            }
          }

          .nav {
            flex-grow: 1;
            justify-content: space-between;
            align-items: center;

            ul {
              li {
                display: inline-block;
                margin: 0 $px16;

                a {
                  display: inline-block;
                  color: #464646;
                  cursor: pointer;
                  text-decoration: none;
                  font-size: $px18;
                  font-weight: 600;
                }

                &:last-child {
                  margin-right: 0;
                }
              }

              > li {
                &:hover {
                  > ul {
                    display: block;
                  }
                }
              }
            }

            .right {
              display: flex;
              align-items: center;
              gap: $px24;

              > .admin {
                font-size: 0;

                > .img {
                  width: $px22;
                  height: $px22;
                }
              }

              > button {
                background: none;
                border: 0;
                padding: 0;
                margin: 0;
                font-size: 0;
                display: inline-block;

                img {
                  width: $px22;
                  height: $px22;
                }
              }

              .btn {
                margin-left: $px4;
                padding: $px10 $px14;
              }
            }
          }

          .picture {
            position: relative;
            cursor: pointer;
            display: inline-block;
            font-size: 0;

            .bubble {
              background: #49545f;
              border-radius: $px4 $px4 $px4 0;
              color: #fff;
              display: inline-block;
              font-size: $px10;
              position: absolute;
              top: $px-9;
              right: $px-16;
              text-align: center;
              padding: $px1 $px3;
            }
          }
        }
      }
    }

    .mobile {
      display: none;
      scroll-behavior: auto;

      .container {
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: $px48;

          .logo {
            font-size: 0;

            > .img {
              width: $px156;
              height: $px24;
            }
          }

          .actions {
            font-size: 0;
            display: flex;
            gap: $px16;
            align-items: center;

            > button {
              background: none;
              border: 0;
              padding: 0;
              margin: 0;
              font-size: 0;
              display: inline-block;

              > img {
                height: $px24;
                width: $px24;
              }
            }
          }
        }

        .nav {
          width: 100%;
          display: block;

          .account {
            background: #f9f9f9;
            border-radius: $px24;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: $px16;
            margin-bottom: $px30;
            padding: $px24 $px20;

            .profile {
              display: flex;
              align-items: center;
              gap: $px18;

              .picture {
                font-size: 0;
              }
            }

            > .btn {
              background-color: #cfede2;
              border-radius: $px12;
              color: #19b681;
              padding: $px10 $px20;
              white-space: nowrap;
            }

            .actions a {
              font-weight: 500;
            }
          }

          > .menus {
            > .title {
              font-size: $px18;
              font-weight: 700;
              margin-bottom: $px7;
            }

            > ul li {
              padding: $px10 0;

              > a {
                display: flex;
                gap: $px10;
                align-items: center;

                > .img {
                  width: $px24;
                  height: $px24;
                }
              }
            }

            &:not(:last-child) {
              padding-bottom: $px15;
            }
          }

          > .actions {
            > .btn {
              padding: $px10 $px20;
              border-radius: $px12;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    > .banners {
      > .banner {
        padding-left: $px15;

        .text {
          font-size: $px14;
        }
      }
    }

    > .wrapper {
      .desktop {
        display: none;
      }

      .mobile {
        display: block;
        scroll-behavior: auto;
      }
    }

    &.mobile-no-fixed {
      position: static;
    }

    &.opened {
      > .wrapper .mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        margin-right: auto;
        margin-left: auto;
        overflow: auto;
        padding-bottom: $px20;

        .container {
          .header {
            padding-top: $px22;
            padding-bottom: $px18;
            position: fixed;
            width: calc(100% - 30px);
            background-color: #fff;
          }

          .nav {
            padding-top: $px74;
          }
        }
      }
    }
  }
}
</style>