<template>
  <div class="reservation-card" :class="{skeleton, clickable}" :data-page="page">
    <div class="inner">
      <Anchor class="profile" :href="`/channel/${memberSeq}?tabs=projects`" title="진행자 채널로 이동">
        <MemberPicture :memberSeq="memberSeq" size="28"/>
        <span class="font-xs">{{ memberName }}</span>
      </Anchor>
      <div class="background" :class="ratio">
        <Anchor :href="link" v-if="clickable || page === 'home'">
          <div class="img" :style="{ backgroundImage: `url(${computedThumbnailUrl})`, backgroundPosition: mainImagePosition || 'center' }"></div>
        </Anchor>
        <div v-else class="img" :style="{ backgroundImage: `url(${computedThumbnailUrl})`, backgroundPosition: mainImagePosition || 'center' }"></div>
      </div>
      <div class="panel">
        <div class="above">
          <div class="title">
            <Anchor :href="link" v-if="clickable || page === 'home'">
              <span>{{ projectName }}</span>
            </Anchor>
            <span v-else>{{ projectName }}</span>
          </div>
          <div class="desc" v-if="page !== 'home'">
            <span>{{ simpleText }}</span>
          </div>
        </div>
        <div class="bottom">
          <div class="tags">
            <span class="badge badge-primary-soft" v-if="page !== 'home'">{{ projectType }}</span>
            <span class="badge badge-point-soft">{{ state.applyCnt }}명 신청</span>
          </div>
          <button class="btn btn-default" :class="{applied: state.applyFlag === '1'}" @click="book()" v-if="page === 'home'">
            <span class="img" :style="{backgroundImage: `url(/assets/ico/component.reservation.bell${ state.applyFlag === '1'? '.active' : ''}.svg)`}" title="알림 신청"></span>
          </button>
          <ReservationBtn :applyFlag="state.applyFlag"
                          :change="change"
                          :clickable="clickable"
                          :imgUrl="computedThumbnailUrl"
                          :link="link"
                          :page="page"
                          :projectName="projectName"
                          :reservationSeq="reservationSeq"
                          :reservationCate="reservationCate"
                          :skeleton="skeleton"
                          v-else
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import MemberPicture from "./MemberPicture";
import mixin from "@/scripts/mixin";
import ReservationBtn from "@/components/ReservationBtn";
import Anchor from "@/components/Anchor";
import store from "@/scripts/store";
import redirectCallback from "@/scripts/redirectCallback";

function Component(initialize) {
  this.name = "componentReservationCard";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Anchor, ReservationBtn, MemberPicture},
  props: {
    applyFlag: String,
    applyCnt: Number,
    clickable: Boolean,
    link: String,
    memberName: String,
    mainImagePosition: String,
    memberSeq: String,
    page: String,
    projectName: String,
    projectSeq: String,
    projectType: String,
    ratio: String,
    reservationCate: String,
    reservationSeq: Number,
    skeleton: Boolean,
    simpleText: String,
    thumbFilePath: String,
  },
  setup(props) {
    const component = new Component(() => {
      state.applyCnt = props.applyCnt;
      state.applyFlag = props.applyFlag;
    });

    const state = reactive({
      applyCnt: 0,
      applyFlag: "0"
    });

    const computedThumbnailUrl = computed(() => {
      return store.getters.thumbnailUrl(props.thumbFilePath);
    });

    const change = (applyFlag) => {
      state.applyFlag = applyFlag;
      state.applyCnt = state.applyCnt + (applyFlag === "0" ? -1 : 1);
    };

    const book = () => {
      const args = {
        reservationSeq: props.reservationSeq,
        projectName: props.projectName,
        applyFlag: state.applyFlag,
      };

      if (!store.state.account.loggedIn) {
        return redirectCallback.setAndLogin("reserveProjectOpenNotice", args);
      }

      redirectCallback.run("reserveProjectOpenNotice", {
        ...args,
        change: change
      });
    };

    return {component, state, computedThumbnailUrl, book, change};
  },
});
</script>

<style lang="scss" scoped>
.reservation-card {
  border-radius: $px4;

  .inner {
    position: relative;

    .profile {
      display: flex;
      gap: $px8;
      align-items: center;
      margin-bottom: $px10;
      width: fit-content;

      > .img {
        flex-shrink: 0;
      }

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &:hover {
        text-decoration: none;
      }
    }

    > .background {
      display: block;
      padding-top: $ratio43Percent;
      position: relative;
      overflow: hidden;
      transition: filter 0.18s;
      border-radius: $thumbBorderRadius;

      .img {
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-color: #eee;
        width: 100%;
        height: 100%;
        display: inline-block;
        transition: transform 0.18s;
        position: absolute;
        top: 0;
        left: 0;
      }

      &.square {
        padding-top: 100%;
      }
    }

    .panel {
      margin-top: $px16;

      .above {
        overflow: hidden;
        height: $px92;
        line-height: 1.2;

        > .title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          > a {
            text-decoration: none;
          }
        }

        > .desc {
          color: #777;
          font-size: $px14;
          letter-spacing: -.25px;
          margin-top: $px4;
          word-wrap: break-word;

          span {
            white-space: normal;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical
          }
        }
      }

      .bottom {
        margin-top: $px8;

        .tags {
          > .badge {
            padding: $px8 $px16;
            border-radius: $px11;

            &:not(:last-child) {
              margin-right: $px4;
            }
          }
        }

        > .btn {
          border-radius: $px100;
          width: $px44;
          height: $px44;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          > .img {
            width: $px18;
            height: $px18;
          }

          &.applied {
            border-color: $colorPoint;
          }
        }

        .reservation-btn {
          margin-top: $px8;
        }
      }
    }
  }

  &.skeleton {
    .inner {
      .profile {
        text-decoration: none;

        > .img, span {
          @include skeleton;
        }
      }

      > .background {
        @include skeleton;

        .img {
          visibility: hidden;
        }
      }

      .panel {
        .above {
          height: $px37;

          .title {
            @include skeleton;
          }

          .desc {
            @include skeleton;
            color: transparent;
          }
        }

        .bottom {
          .tags .badge, > .btn, > .btn span {
            @include skeleton;
          }
        }
      }
    }
  }

  &[data-page=home] {
    .inner {
      .panel {
        .above {
          height: $px40;
        }

        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: $px16;
        }
      }
    }

    &.clickable:not(.skeleton) {
      .inner .background:hover {
        .img {
          transform: scale(1.1);
        }
      }
    }
  }

  @media(max-width: 991px) {
    &[data-page=list] .inner .panel {
      .above {
        height: auto;
      }
    }
  }

  @media(max-width: 767px) {
    &[data-page=home] .inner {
      .panel {
        .above {
          height: $px36;
        }

        .bottom > .btn {
          width: $px40;
          height: $px40;
        }
      }
    }

    .inner {
      .panel {
        margin-top: $px12;

        .above > .title > a {
          font-size: $px14;
        }

        .tags {
          > .badge {
            padding: $px4 $px12;
            font-size: $px12;
          }
        }
      }
    }
  }
}
</style>