<template>
  <div class="reservation-btn" :class="{ skeleton }" :data-page="page">
    <button class="btn btn-default btn-block" :class="{on: applyFlag === '1'}" v-if="!clickable || ['detail', 'home'].includes(page)" @click="book()">
      <span class="font-sm">{{ applyFlag === "1" ? "알림 신청 중" : "알림 신청하기" }}</span>
      <span class="img" :style="{backgroundImage: `url(/assets/ico/component.reservation.bell${applyFlag === '1' ? '.active' : ''}.svg)`}"></span>
    </button>
    <Anchor :href="link" class="btn btn-default btn-block" v-else>
      <span class="font-sm">상세보기</span>
    </Anchor>
    <button class="share btn btn-default" title="공유하기" @click="openShareModal()">
      <span class="img"></span>
    </button>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import definitions from "@/scripts/definitions";
import redirectCallback from "@/scripts/redirectCallback";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "componentReservationBtn";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  props: {
    applyFlag: String,
    change: Function,
    clickable: Boolean,
    imgUrl: String,
    link: String,
    page: String,
    projectName: String,
    reservationSeq: Number,
    reservationCate: String,
    skeleton: Boolean,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const book = () => {
      const args = {
        reservationSeq: props.reservationSeq,
        projectName: props.projectName,
        applyFlag: props.applyFlag,
      };

      if (!store.state.account.loggedIn) {
        return redirectCallback.setAndLogin("reserveProjectOpenNotice", args);
      }

      redirectCallback.run("reserveProjectOpenNotice", {
        ...args,
        change: props.change
      });
    };

    const openShareModal = () => {
      store.commit("openModal", {
        name: "Share",
        params: {
          path: props.link,
          title: props.projectName,
          img: {
            url: props.imgUrl,
            width: definitions.thumbnail.width,
            height: definitions.thumbnail.height
          }
        }
      });
    };

    return {component, book, openShareModal};
  }
});
</script>

<style lang="scss" scoped>
.reservation-btn {
  display: flex;
  align-items: center;
  gap: $px8;

  .btn {
    box-shadow: none !important;
    margin-top: 0;
    height: $px48;
    position: relative;
    border-radius: $px8;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $px8;

    .img {
      background-image: url('/assets/ico/common.share.svg');
      height: $px18;
      width: $px18;
    }

    &.on {
      color: $colorPoint;
      border-color: $colorPoint;
    }
  }

  &.skeleton {
    .btn {
      @include skeleton;

      .img {
        visibility: hidden;
      }
    }
  }

  @media(max-width: 767px) {
    &[data-page=home] {
      height: auto;

      .btn {
        &.share {
          display: none;
        }
      }
    }

    &[data-page=detail] {
      height: $px46;

      .btn {
        &.share {
          width: $px46;

          .img {
            width: $px18;
            height: $px18;
          }
        }
      }
    }
  }
}
</style>