<template>
  <span
      class="img" :class="{skeleton, squircle}"
      :style="{ backgroundImage: `url('${hide ? '/assets/img/common.profile.default.svg' :computedProfilePath}')`, width: computedSize + 'px', height: computedSize + 'px' }"
      @click="typeof click === 'function' && click()"
  ></span>
</template>

<script>
import {computed, defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import env from "@/scripts/env";

function Component(initialize) {
  this.name = "componentMemberPicture";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    memberSeq: String,
    size: String,
    click: Function,
    hide: Boolean,
    skeleton: Boolean,
    squircle: Boolean,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const defaultSize = 33;

    const computedSize = computed(() => {
      return props.size || defaultSize;
    });

    const computedProfilePath = computed(() => {
      if (props.memberSeq) {
        return `/profile/${props.memberSeq}`;
      }

      return env.profileFileDefaultPath;
    });

    return {component, defaultSize, computedSize, computedProfilePath,};
  },
});
</script>

<style lang="scss" scoped>
.img {
  border: $px1 solid #eaeaea;
  border-radius: 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #eaeaea;
  background-position: center;

  &.skeleton {
    background: $colorBackground !important;
    border-color: #eee;
  }

  &.squircle {
    border-radius: 40%;
  }
}
</style>