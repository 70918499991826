<template>
  <div class="card-reward-bottom">
    <div class="info">
      <div class="above" :style="{ height: computedAboveHeight }">
        <div class="title">
          <Anchor :href="link" class="pointer no-underline">{{ projectName }}</Anchor>
        </div>
        <div class="desc" v-if="simpleText !== undefined">
          <span>{{ simpleText }}</span>
        </div>
      </div>
      <div class="status">
        <div class="font-sm" v-if="hideState">
          <span>모금 현황 </span>
          <span class="color-point">비공개 프로젝트</span>
          <span>입니다.</span>
        </div>
        <template v-else-if="percent !== undefined">
          <div class="graph" v-if="page !== 'home'">
            <div
                class="progress-bar bg-point"
                role="progressbar"
                :style="{ width: (percent > 100 ? 100 : percent) + '%' }"
                aria-label="진행 표시줄"
                :aria-valuenow="percent"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
          </div>
          <div class="summary">
            <div class="left">
              <b class="color-point font-lg" v-if="page === 'home' || progressOrder === 1">{{ $lib.getNumberFormat(percent) }}<span class="sign">%</span></b>
              <span class="font-sm pl-1 font-weight-light" v-if="amount !== undefined">{{ $lib.getNumberFormat(amount) }}{{ computedCurrencyUnit }}</span>
            </div>
            <div class="right">
              <span class="font-xs" v-if="page !== 'home' && progressOrder !== 1">모집 {{ successFailName }}</span>
              <template v-else-if="dday !== undefined">
                <span class="font-xs" v-if="dday === 0">오늘 마감</span>
                <span class="font-xs" v-else>{{ dday ? $lib.getNumberFormat(dday) + "일 남음" : "마감" }}</span>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import MemberPicture from "./MemberPicture";
import mixin from "@/scripts/mixin";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "componentRewardCardBottom";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Anchor, MemberPicture},
  props: {
    amount: Number,
    currencyUnit: String,
    dday: Number,
    hideState: Boolean,
    link: String,
    percent: Number,
    page: String,
    progressOrder: Number,
    projectName: String,
    successFailName: String,
    simpleText: String,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({});

    const computedAboveHeight = computed(() => {
      let value = 0;

      const add = (v) => {
        value += v;
      };

      if (props.page === "open") {
        add(120);
      } else {
        add(40);
        props.simpleText !== undefined && add(27);
      }

      return value + "px";
    });

    const computedCurrencyUnit = computed(() => {
      return props.currencyUnit || "원";
    });

    return {
      component
      , state
      , computedAboveHeight
      , computedCurrencyUnit
    };
  },
});
</script>

<style lang="scss" scoped>
.card-reward-bottom {
  padding-top: $px16;

  .info {
    .above {
      > .title {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 1.2;
      }
    }

    .status {
      .graph {
        background-color: #e1e1e1;
        border-radius: $px2;
        height: $px3;
        margin-top: $px10;
        position: relative;
        width: 100%;

        > div {
          height: 100%;
          min-width: 2%;
        }
      }

      .summary {
        display: flex;
        gap: $px16;
        justify-content: space-between;
        align-items: flex-end;

        .right {
          padding: $px4;
          font-size: $px14;
          color: $colorTextSecondary;
          background-color: #eee;
          border-radius: $px2;
          line-height: 1;
        }
      }
    }
  }

  .status {
    .summary {
      displa
      .left {

      }
    }
  }
}
</style>