<template>
  <div class="card" :class="{skeleton}" :data-page="page" :data-type="projectType" :style="{ height: height ? height + 'px' : '' }">
    <div class="inner1">
      <div class="inner2">
        <Anchor :href="computedLink" class="background pointer" :click="click">
          <div class="bg" :style="{backgroundImage: `url('${computedThumbnailUrl}')`, backgroundPosition: mainImagePosition || 'center'}"></div>
        </Anchor>
        <button class="heart btn pointer img" title="관심 프로젝트"
                :style="{ opacity: state.toggling ? 0 : 1, backgroundImage: `url(/assets/ico/component.card.heart${$store.state.interests.sequences[projectType] && $store.state.interests.sequences[projectType].includes(projectSeq) ? '.active' : ''}.svg)` }"
                :disabled="!$store.state.interests.loaded"
                @click="toggleInterest({projectSeq, projectType, projectName})"
                v-if="['invest','reward'].includes(projectType) && interest && (projectType === 'invest' ? fundingType !== '02' : true)"></button>
        <span class="badge badge-secondary" v-if="badge !== undefined">{{ badge }}</span>
        <div class="below">
          <div class="profile" v-if="!noProfile">
            <Anchor :href="`/channel/${builderSeq}?tabs=projects`" v-if="page !== 'open'">
              <MemberPicture :memberSeq="builderSeq" :skeleton="skeleton" size="25"/>
              <div class="name" :title="builderName">{{ builderName }}</div>
            </Anchor>
            <div class="tags" v-if="projectType === 'invest'">
              <span class="badge badge-point" v-if="stockTypeText">{{ stockTypeText }}</span>
              <span class="badge badge-secondary" v-if="fundingType === '02'">사모</span>
              <span class="badge badge-secondary" v-if="rewardFlag === 'Y'">리워드</span>
              <span class="badge badge-danger" v-if="incomeDeductionFlag === 'Y'">소득공제</span>
            </div>
          </div>
          <div class="texts" :class="{'new': percent === undefined && page === 'home'}">
            <div class="proj">
              <div class="above" :style="{ height: computedAboveHeight }">
                <div class="title">
                  <Anchor :href="computedLink" :click="click" class="pointer">{{ projectName }}</Anchor>
                </div>
                <div class="desc" v-if="simpleText !== undefined">
                  <span>{{ simpleText }}</span>
                </div>
                <div class="desc" v-if="period !== undefined">
                  <span>{{ period }}</span>
                </div>
                <div class="desc" v-if="partnership !== undefined">
                  <span>{{ partnership }}</span>
                </div>
                <div class="desc" v-if="createDate !== undefined">
                  <span>{{ createDate }}</span>
                </div>
              </div>
              <div class="status" :class="{open: page === 'open'}">
                <div class="font-sm" v-if="stateHide">
                  <span>모금 현황 </span>
                  <span class="color-point">비공개 프로젝트</span>
                  <span>입니다.</span>
                </div>
                <template v-else-if="percent !== undefined">
                  <template v-if="page !== 'home' && progressOrder !== 1">
                    <div class="bottom finished" v-if="count !== undefined">
                      <div class="left-side">
                        <span class="percent color-point">{{ $lib.getNumberFormat(percent) }}<span class="sign">%</span></span>
                        <span class="amount">
                          <span v-if="contestSeq === '745'">모인금액 </span>
                          <span>{{ $lib.getNumberFormat(amount) }}{{ computedCurrencyUnit }}</span>
                        </span>
                        <span class="amount" v-if="contestSeq === '745'">/ 목표금액 {{ $lib.getNumberFormat(targetAmt) }}{{ computedCurrencyUnit }}</span>
                      </div>
                      <div class="right-side" v-if="contestSeq !== '745'">
                        <span>모집 {{ successFailName }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="!computedPrivateEquityBtnVisible">
                      <div class="graph" v-if="page !== 'home'">
                        <div class="progress">
                          <div
                              class="progress-bar bg-point"
                              role="progressbar"
                              :style="{ width: (percent > 100 ? 100 : percent) + '%' }"
                              aria-label="진행 표시줄"
                              :aria-valuenow="percent"
                              aria-valuemin="0"
                              aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="bottom" v-if="percent !== undefined">
                        <div class="left-side">
                          <span class="percent color-point" v-if="page === 'home' || progressOrder === 1">{{ $lib.getNumberFormat(percent) }}<span class="sign">%</span></span>
                          <span class="amount" v-if="amount !== undefined">{{ $lib.getNumberFormat(amount) }}{{ computedCurrencyUnit }}</span>
                        </div>
                        <div class="right-side" v-if="dday !== undefined && contestSeq !== '745'">
                          <span v-if="dday === 0">오늘 마감</span>
                          <span v-else>{{ dday ? $lib.getNumberFormat(dday) + "일 남음" : "마감" }}</span>
                        </div>
                      </div>
                    </template>
                    <div class="buttons clearfix" v-else>
                      <button class="btn btn-point btn-block" @click="privateEquityEvent('finished')" v-if="privateEquityApprovedCnt >= 49">신청 마감</button>
                      <button class="btn btn-point btn-block" @click="privateEquityEvent('waiting')" v-else-if="privateEquityParticipantStatusCode === 0">승인 대기 중</button>
                      <button class="btn btn-point btn-block" @click="privateEquityEvent('reApply')" v-else-if="privateEquityParticipantStatusCode === 2">펀드 참여 재신청</button>
                      <button class="btn btn-point btn-block" @click="privateEquityEvent('apply')" v-else>펀드 참여 신청</button>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="actions" v-if="page === 'open'">
            <template v-if="projectType === 'invest'">
              <template v-if="regState === 'STATUS_0006'">
                <a :href="`/${projectType}/${projectSeq}`" target="_blank" rel="noopener noreferrer" class="btn" title="바로가기" v-if="regState === 'STATUS_0006'">
                  <span>바로가기</span>
                  <i class="fa fa-external-link"></i>
                </a>
                <a :href="`/manage/${projectType}/${projectSeq}`" target="_blank" rel="noopener noreferrer" class="btn" title="관리" v-if="regState === 'STATUS_0006'">
                  <span>관리하기</span>
                  <i class="fa fa-cog"></i>
                </a>
              </template>
              <template v-else>
                <a :href="`/${projectType}/preview/${projectSeq}`" target="_blank" rel="noopener noreferrer" class="btn" title="미리보기">
                  <span>미리보기</span>
                  <i class="fa fa-search"></i>
                </a>
              </template>
            </template>
            <template v-else>
              <a :href="`/${projectType === 'invest' ? 'invest' : 'reward'}/${projectSeq}`" target="_blank" rel="noopener noreferrer" class="btn" title="바로가기" v-if="regState === 'STATUS_0006'">
                <span>바로가기</span>
                <i class="fa fa-external-link"></i>
              </a>
              <a :href="`/${projectType === 'invest' ? 'invest' : 'reward'}/preview/${projectSeq}`" target="_blank" rel="noopener noreferrer" class="btn" title="미리보기" v-else>
                <span>미리보기</span>
                <i class="fa fa-search"></i>
              </a>
              <a :href="`/apply/${projectSeq}`" target="_blank" rel="noopener noreferrer" class="btn" title="수정하기" v-if="['STATUS_0001', 'STATUS_0002', 'STATUS_0003', 'STATUS_0004'].includes(regState)">
                <span>수정하기</span>
                <i class="fa fa-pencil"></i>
              </a>
              <a :href="`/manage/${projectType}/${projectSeq}`" target="_blank" rel="noopener noreferrer" class="btn" title="관리" v-else>
                <span>관리하기</span>
                <i class="fa fa-cog"></i>
              </a>
              <button class="btn" @click="remove(projectSeq)" title="삭제하기" v-if="['STATUS_0001', 'STATUS_0002', 'STATUS_0004'].includes(regState)">
                <span>삭제하기</span>
                <i class="fa fa-trash"></i>
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import MemberPicture from "./MemberPicture";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Anchor from "@/components/Anchor";
import redirectCallback from "@/scripts/redirectCallback";

function Component(initialize) {
  this.name = "componentCard";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Anchor, MemberPicture},
  props: {
    page: String,
    projectSeq: Number,
    projectType: String,
    amount: Number,
    targetAmt: Number,
    percent: Number,
    dday: Number,
    progressOrder: Number,
    thumbFilePath: String,
    projectName: String,
    link: String,
    builderSeq: String,
    builderName: String,
    builderImageUrl: String,
    simpleText: String,
    projectCate: String,
    fundingType: String,
    stockTypeText: String,
    rewardFlag: String,
    incomeDeductionFlag: String,
    count: Number,
    height: Number,
    successFailName: String,
    skeleton: Boolean,
    interest: Boolean,
    noProfile: Boolean,
    badge: String,
    period: String,
    regState: String,
    createDate: String,
    partnership: String,
    remove: Function,
    privateEquityApprovedCnt: Number,
    privateEquityParticipantStatusCode: Number,
    stateHide: Boolean,
    applyCnt: Number,
    mainImagePosition: String,
    contestSeq: String,
    currencyUnit: String,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({
      toggling: false
    });

    const computedLink = computed(() => {
      return computedPrivateEquityBtnVisible.value || computedPrivateEquityInaccessible.value ? undefined : props.link || undefined;
    });

    const computedCurrencyUnit = computed(() => {
      return props.currencyUnit || "원";
    });

    const computedPrivateEquityBtnVisible = computed(() => {
      return props.projectType === "invest" && props.fundingType === "02" && props.progressOrder === 1 && props.privateEquityParticipantStatusCode !== 1;
    });

    const computedPrivateEquityInaccessible = computed(() => {
      return props.projectType === "invest" && props.fundingType === "02" && (!["MEMBER_MID_ADMIN", "MEMBER_TOP_ADMIN"].includes(store.state.account.memberAuth) && props.privateEquityParticipantStatusCode !== 1);
    });

    const computedThumbnailUrl = computed(() => {
      return store.getters.thumbnailUrl(props.thumbFilePath);
    });

    const click = () => {
      props.projectType === "invest" && props.fundingType === "02" && store.dispatch("openLoginModalIfNotLoggedIn");
    };

    const toggleInterest = ({projectSeq, projectType, projectName}) => {
      const args = {projectSeq, projectType, projectName};

      if (!store.state.account.loggedIn) {
        return redirectCallback.setAndLogin("toggleProjectInterest", args);
      }

      state.toggling = true;
      redirectCallback.run("toggleProjectInterest", {
        ...args,
        onSuccess() {
          state.toggling = false;
        },
        onFail() {
          state.toggling = false;
        }
      });
    };

    const computedAboveHeight = computed(() => {
      let value = 0;

      const add = (v) => {
        value += v;
      };

      if (props.page === "open") {
        add(120);
      } else {
        add(50);
        props.simpleText !== undefined && add(27);
        props.period !== undefined && add(27);
        props.createDate !== undefined && add(27);
        props.partnership !== undefined && add(27);
      }

      return value + "px";
    });

    const privateEquityEvent = (status) => {
      if (status === "apply") {
        if (!store.state.account.loggedIn) {
          store.dispatch("goLoginPage");
        } else {
          apply();
        }
      } else if (status === "finished") {
        window.alert("이미 참가 모집이 완료되었습니다.");
      } else if (status === "waiting") {
        window.alert("담당자 승인 대기중입니다.");
      } else if (status === "reApply") {
        apply();
      }
    };

    const apply = () => {
      store.commit("openModal", {
        name: "PrivateEquityApply",
        params: {projectSeq: props.projectSeq},
        callback: "refresh"
      });
    };

    return {component, state, click, computedLink, computedCurrencyUnit, computedPrivateEquityBtnVisible, computedAboveHeight, computedThumbnailUrl, toggleInterest, privateEquityEvent, apply};
  },
});
</script>

<style lang="scss" scoped>
.card {
  border: none;
  border-radius: $px4;

  .inner1 {
    .inner2 {
      position: relative;

      > .background {
        display: block;
        padding-top: $ratio43Percent;
        position: relative;
        overflow: hidden;
        transition: filter 0.18s;
        border-radius: $px4 $px4 0 0;

        > .bg {
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-position: 50% 50%;
          background-color: #eee;
          width: 100%;
          height: 100%;
          display: inline-block;
          transition: transform 0.18s;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      > .heart {
        position: absolute;
        top: 0;
        right: 0;
        width: $px20;
        height: $px20;
        padding: $px20;
        border: 0;
        background-size: $px20 $px20;
        background-position: 50%;
      }

      > .private-equity {
        position: absolute;
        top: $px7;
        left: $px7;
        width: $px46;
        height: $px46;

        > .img {
          width: 100%;
          height: 100%;
        }
      }

      > .badge {
        position: absolute;
        top: $px15;
        left: $px15;
      }

      > .below {
        > .profile {
          position: relative;
          padding-top: $px9;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > a {
            display: flex;
            align-items: center;

            .img {
              overflow: hidden;
              background-position: 50%;
              flex-shrink: 0;
              margin-right: $px5;
            }

            .name {
              font-size: $px12;
              color: #555;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
          }

          .tags {
            font-size: $px10;
            max-width: $px180;
            white-space: nowrap;

            span {
              padding: $px4 $px7;

              &.badge-danger {
                background-color: #f74242;
              }

              &:not(:last-child) {
                margin-right: $px3;
              }
            }
          }
        }

        > .texts {
          position: relative;
          margin-top: $px5;

          .proj {
            .above {
              overflow: hidden;

              .title {
                margin-bottom: $px7;

                a {
                  font-size: $px16;
                  line-height: 1.4;
                  text-decoration: none;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: block;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  font-weight: 500;
                }
              }

              .desc {
                font-size: $px14;
                word-wrap: break-word;
                color: #999;
                padding-top: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                word-break: keep-all;
              }
            }

            .status {
              overflow: hidden;
              position: relative;
              width: 100%;
              height: $px40;

              .graph {
                background-color: #e1e1e1;
                border-radius: $px2;
                height: $px3;
                margin-top: $px10;
                position: relative;
                width: 100%;

                .progress {
                  height: 100%;

                  > div {
                    min-width: 2%;
                  }
                }
              }

              .bottom {
                height: $px36;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left-side {
                  font-size: $px15;

                  .percent {
                    font-size: $px17;
                    font-weight: 600;
                    margin-right: $px7;

                    .sign {
                      font-weight: 500;
                    }
                  }

                  .amount {
                    font-weight: 400;
                    font-size: $px14;
                    color: #777;
                    letter-spacing: -0.7px;
                  }
                }

                .right-side {
                  color: #777;
                  font-size: $px14;
                  letter-spacing: -0.7px;

                  span {
                    display: inline-block;
                    vertical-align: middle;
                  }
                }

                &.finished {
                  padding-top: $px10;
                  margin-top: $px3;
                }
              }

              .buttons {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                .btn {
                  height: 100%;
                  padding: $px9;
                  font-size: $px14;
                }
              }

              &.open {
                height: auto;
              }
            }
          }

          &.new {
            margin-top: $px15;
          }
        }

        > .actions {
          display: flex;
          gap: $px12;

          > .btn {
            background-color: $colorBackground;
            font-size: $px14;
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $px6;
          }
        }
      }
    }
  }

  &.skeleton {
    .inner1 .inner2 {
      > .background {
        @include skeleton;

        > .bg {
          visibility: hidden;
        }
      }

      > .below {
        > .profile {
          > a {
            .name {
              @include skeleton;
              color: transparent;
            }

            &:hover {
              text-decoration: none;
            }
          }

          .tags span {
            @include skeleton;
          }
        }

        > .texts .proj {
          .above {
            .title {
              @include skeleton;

              a {
                color: transparent;
              }
            }

            .desc > span {
              @include skeleton;
              color: transparent;
            }
          }

          .status {
            .graph {
              .progress {
                @include skeleton;

                > div {
                  visibility: hidden;
                }
              }
            }

            .bottom {
              .left-side {
                @include skeleton;
                color: transparent !important;
                min-width: $px100;

                > span {
                  visibility: hidden;
                }
              }

              .right-side {
                > span {
                  @include skeleton;
                  color: transparent !important;
                  min-width: $px50;
                }

                .img {
                  visibility: hidden;
                }
              }
            }
          }
        }

        > .actions {
          .btn {
            @include skeleton;
          }
        }
      }

      > .heart {
        display: none;
      }

      > .badge {
        @include skeleton;
      }
    }
  }

  &[data-page=home] {
    .inner1 .inner2 .texts .proj {
      .above .title a {
        font-size: $px15;
      }

      .status {
        height: auto;

        .bottom {
          height: auto;
        }
      }
    }
  }

  &[data-type=invest] .inner1 .inner2 > .below > .texts .proj .above .title {
    margin-bottom: 0;
  }

  &:not(.skeleton) {
    &:hover {
      .inner1 .inner2 .background {
        > .bg {
          transform: scale(1.1);
        }
      }
    }
  }

  @media(max-width: 1199px) {
    &[data-page=home] .inner1 .inner2 > .below .texts .proj .status .bottom .right-side {
      display: none;
    }
  }

  @media(max-width: 991px) {
    .inner1 .inner2 > .below {
      > .profile .tags {
        font-size: $px12;
      }

      > .texts .proj {
        .above .title a {
          font-size: $px17;
        }

        .status {
          .bottom {
            .left-side {
              font-size: $px16;

              .amount {
                font-size: $px14;
              }
            }

            .right-side {
              font-size: $px14;
            }

            &.finished {
              padding-top: 0;
              margin-top: 0;

              .left-side {
                margin-top: $px2;
              }

              .right-side {
                margin-top: $px2;
              }
            }
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    .inner1 .inner2 > .heart {
      width: $px17;
      height: $px17;
      padding: $px15 $px17;
      background-size: $px17 $px17;
    }

    &[data-page=home] .inner1 .inner2 {
      > .below {
        .profile {
          padding: $px5 0 0 0 !important;
          color: #666;
          margin-bottom: $px2;

          .img {
            display: none;
          }

          .name {
            margin-top: 0;
            font-weight: normal;
            font-size: $px10;
          }
        }

        .texts {
          padding-top: 0 !important;

          .proj {
            .above {
              height: $px35 !important;

              .title a {
                line-height: 1.4;
                font-size: $px12;
              }
            }

            .status {
              padding: 0;
              margin-top: 0;
              height: auto;

              .bottom {
                height: $px20;

                .left-side {
                  display: block;
                  float: none;
                  font-size: $px12;

                  .amount {
                    margin-right: $px5;
                  }
                }

                .percent {
                  font-size: $px14;
                }

                .amount {
                  font-size: $px12;
                }
              }
            }
          }

          &.new {
            margin-top: $px5;
          }
        }
      }
    }

    &:not([data-page=home]) .inner1 .inner2 > .below {
      padding-bottom: $px11;

      > .texts .proj {
        .status {
          height: auto;

          .buttons {
            position: static;
          }

          .graph {
            margin-top: $px10;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    &:not([data-page=home]) .inner1 .inner2 > .below > .texts .proj .above {
      height: auto !important;
      margin-bottom: $px5;
    }
  }
}
</style>