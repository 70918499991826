<template>
  <div class="partner-card" :class="{skeleton}" :data-page="page">
    <router-link :to="link" class="thumb">
      <span class="img" :style="{backgroundImage: `url('${$definitions.urls.imgCdn + thumbImagePath + thumbImageSaveName}')`}"></span>
      <span class="badge badge-point" v-if="progressOrder === 0">진행중</span>
    </router-link>
    <div class="content">
      <div class="profile" v-if="page !== 'home'">
        <span>{{ partnershipName }}</span>
      </div>
      <div class="title font-md bold">
        <router-link :to="link">{{ contestName }}</router-link>
      </div>
      <ul class="tight">
        <li v-if="projectCount !== undefined">
          <span>총 프로젝트</span>
          <div>
            <b class="color-point">{{ $lib.getNumberFormat(projectCount) }}</b>
            <span>개</span>
          </div>
        </li>
        <li v-if="sumAmt !== undefined">
          <span>총 투자 금액</span>
          <div>
            <b class="color-point">{{ $lib.getNumberFormat(sumAmt) }}</b>
            <span>원</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentPartnerCard";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    page: String,
    link: String,
    skeleton: Boolean,
    contestSeq: Number,
    thumbImagePath: String,
    thumbImageSaveName: String,
    partnershipName: String,
    contestName: String,
    contestExplanationText: String,
    projectCount: Number,
    sumAmt: Number,
    progressOrder: Number,
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.partner-card {
  font-size: $px14;

  .thumb {
    display: block;
    padding: 30.36% 0;
    border-radius: $px8;
    position: relative;
    overflow: hidden;
    margin-bottom: $px16;

    > .img {
      transition: transform 0.18s;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #e9ecef;
      background-position: 50%;
      background-size: cover;
    }

    .badge {
      position: absolute;
      top: $px16;
      right: $px16;
      padding: $px6;
      font-size: $px12;
    }

    &:hover > div {
      transform: scale(1.1);
    }
  }

  .content {
    .profile {
      color: #555;
      margin-bottom: $px5;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: $px12;
    }

    .title {
      margin-bottom: $px10;
      height: $px54;
      overflow: hidden;

      a {
        text-decoration: none;
      }
    }

    ul {
      margin: 0;
      font-size: $px16;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: $px8;
        }
      }
    }
  }

  &[data-page=home] {
    .thumb .badge {
      top: $px8;
      right: $px8;
    }

    .content .title {
      margin-bottom: 0;
      height: auto !important;

      a {
        display: inline-block;
        font-size: $px16;
        line-height: 1.4;
        vertical-align: top;
      }
    }
  }

  &.skeleton {
    .thumb {
      @include skeleton;
    }

    .content {
      .profile > span,
      .title > a {
        @include skeleton;
      }

      ul {
        li div {
          @include skeleton;
        }
      }
    }
  }

  @media(max-width: 991px) {
    .content .title {
      height: auto;
    }

    &[data-page=home] {
      .content .title {
        a {
          font-size: $px14;
          white-space: break-spaces;
        }
      }
    }
  }

  @media(max-width: 767px) {
    &[data-page=home] {
      .thumb .badge {
        padding: $px4;
      }
    }
  }
}
</style>