<template>
  <div class="climbing pointer shadow" :class="{ visible: state.visible }" @click="moveTop()" title="맨 위로 이동" :style="{bottom: $store.state.styles.climbing.bottom, display: $store.state.styles.climbing.display}">
    <span class="img" style="background-image: url(/assets/ico/component.climbing.arrow.svg?2)"></span>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "componentClimbing";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({
      visible: false
    });

    const moveTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", () => {
        state.visible = lib.isClimbable();
      }]);
    });

    return {component, state, moveTop};
  },
});
</script>

<style lang="scss" scoped>
.climbing {
  position: fixed;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  height: $px48;
  width: $px48;
  right: $px15;
  bottom: $px15;
  border-radius: $px16;
  text-align: center;
  transition: opacity 0.36s, background-color 0.18s;
  opacity: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
    width: $px22;
    height: $px22;
  }

  &:hover {
    background-color: $colorBackground;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}
</style>