<template>
  <div class="my-page-index">
    <div class="container" v-if="$store.state.account.loggedIn">
      <div class="wrapper">
        <div class="lnb" :id="`${component.name}SideMenu`" :class="[state.menu.name, {expand : state.expand,}]">
          <div class="wrapper no-scrollbar">
            <div class="part" v-for="(s, idx) in menus" :key="`${component.name}Menus${idx}`">
              <div class="subject font-xs">
                <span>{{ s.title }}</span>
              </div>
              <ul class="tight">
                <li :class="{active: m.name === state.menu.name}" v-for="m in s.menu" :key="`${component.name}Item${m.name}`" v-show="m.valid" @click="!m.click ? null : m.click()">
                  <Anchor :href="m.paths[0]" class="pointer">
                    <span class="img" :style="{'background-image': `url(/assets/ico/${m.ico})`}"></span>
                    <span class="txt">{{ m.title }}</span>
                  </Anchor>
                </li>
              </ul>
            </div>
          </div>
          <span class="toggle d-block d-lg-none" @click="toggleExpand()">
            <i class="fa" :class="state.expand ? 'fa-angle-up': 'fa-angle-down'"></i>
          </span>
        </div>
        <div class="content shadow">
          <div class="head">
            <div class="header">
              <div class="title">
                <span v-if="state.menu.name === 'Dashboard'">마이페이지</span>
                <template v-else>
                  <span>{{ state.menu.title }}</span>
                </template>
              </div>
              <div class="desc font-sm" v-if="state.menu.name === 'Dashboard'">
                <template v-if="state.menu.desc">
                  <span class="mr-1">{{ state.menu.desc }}</span>
                  <router-link to="/mypage/investor" class="color-anchor">투자자 인증 받기</router-link>
                </template>
                <span v-else>&nbsp;</span>
              </div>
            </div>
            <div class="profile">
              <div class="picture">
                <MyPicture/>
                <router-link :class="($route.params.param1 !== 'account' || $route.params.param2) ? 'pointer' : 'cursor-init'" :disabled="$route.params.param1 === 'account' && !$route.params.param2"
                             :to="`/channel/${$store.state.account.memberSeq}?tabs=projects`">
                  <span v-if="$route.params.param1 !== 'account' || $route.params.param2">내채널 &nbsp;<i class="fa fa-angle-right"/></span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="body">
            <Component :is="computedComponent" :menu="state.menu" v-if="$store.state.investor.checked"/>
          </div>
        </div>
      </div>
    </div>
    <NoLogin v-else-if="$store.state.account.checked"/>
  </div>
</template>

<script>
import {computed, defineAsyncComponent, defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import router from "@/scripts/router";
import MyPicture from "@/components/MyPicture";
import NoLogin from "@/components/NoLogin";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";
import Anchor from "@/components/Anchor";
import mypageMenu from "@/scripts/mypageMenu";

function Component(initialize) {
  this.name = "pageMyPage";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Anchor, NoLogin, MyPicture},
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "마이페이지");

      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      if (router.app.$route.path === "/mypage") {
        state.menu = menus.mines.menu[0];
      } else {
        for (let i in menus) {
          for (let j in menus[i].menu) {
            if (["Investor"].includes(menus[i].menu[j].name)) {
              if (menus[i].menu[j].paths.some(p => router.app.$route.path === p)) {
                state.menu = menus[i].menu[j];
              }
            } else if (menus[i].menu[j].paths.some(p => router.app.$route.path.startsWith(p))) {
              state.menu = menus[i].menu[j];
            }
          }
        }
      }

      if (!state.menu.name) {
        store.commit("setSwingMessage", definitions.messages.errorCommon);
        return router.replace({path: "/"});
      }

      store.commit("checkInvestor", () => {
        menus.pays.menu.find(p => p.name === "InvestorAccountInfo").valid = store.state.investor.investorAuth === "INVESTOR_STATUS_COMPLETE";
      });
    });

    const state = reactive({
      menu: {
        name: null,
        ico: null,
        title: null,
        desc: null,
        paths: [],
      },
      expand: false,
    });

    const computedComponent = computed(() => {
      let menuName = state.menu.name;

      if (menuName) {
        if (menuName === "Investor") {
          if (store.state.investor.investorAuth === "INVESTOR_STATUS_COMPLETE") {
            menuName = "InvestorStatus";
          } else {
            menuName = "InvestorApply";
          }
        }

        return defineAsyncComponent(() => import(`@/pages/my-page/${menuName}`));
      }

      return defineAsyncComponent(() => import(`@/components/Wait`));
    });

    const menus = mypageMenu.getMenus();

    const toggleExpand = (value) => {
      value !== undefined ? state.expand = value : state.expand = !state.expand;

      const $lnb = document.getElementById(`${component.name}SideMenu`);

      if (!$lnb) {
        return;
      }

      state.expand
          ? ($lnb.style.height = $lnb.querySelector(".wrapper")?.getBoundingClientRect().height + 40 + "px")
          : $lnb.style.height = "";
    };

    return {component, state, computedComponent, menus, toggleExpand};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-index {
  background: $colorBackground;

  > .container {
    padding-top: $px33;
    padding-bottom: $px33;
    position: relative;

    > .wrapper {
      display: flex;

      .lnb {
        flex-shrink: 0;
        margin-right: $px55;

        > .wrapper {
          .part {
            padding: 0 $px3;

            .subject {
              margin-bottom: $px6;
              padding: $px5 $px15 $px5 0;
            }

            ul {
              li {
                background-repeat: no-repeat;
                border: $px1 solid transparent;
                font-weight: 300;
                margin-bottom: $px20;

                > a {
                  display: flex;
                  align-items: center;
                  gap: $px8;
                  text-decoration: none;
                  font-size: $px14;
                  line-height: 1;

                  > .img {
                    width: $px20;
                    height: $px20;
                    transition: 0.4s filter;
                    opacity: 0.65;
                  }
                }

                &.active {
                  > a {
                    color: $colorPoint;
                  }
                }

                &.active {
                  font-weight: 500;
                }
              }
            }

            &:not(:last-child) {
              margin-bottom: $px25;
            }
          }
        }
      }

      .content {
        background: #fff;
        border-radius: $px6;
        flex-grow: 1;
        min-width: 0;

        .head {
          padding: $px40 $px200 0 $px50;
          position: relative;

          .header {
            padding-bottom: $px15;

            .title {
              font-size: $px22;
              font-weight: 500;
            }

            .desc {
              margin-top: $px15;
              color: #767676;
            }
          }

          .profile {
            text-align: center;
            position: absolute;
            top: $px25;
            right: $px50;

            .picture {
              display: inline-block;

              > a {
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;

                > span {
                  display: inline-block;
                  font-size: $px13;
                  margin-top: $px13;
                  line-height: 1;
                }

                &:hover > span {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .body {
          padding: $px50 $px50 $px50 $px50;
        }
      }
    }
  }

  @media(min-width: 992px) {
    .container > .wrapper {
      .lnb {
        height: 100%;

        > .wrapper {
          height: 100%;
        }
      }

      .content {
        min-height: 900px;
      }
    }
  }

  @media(max-width: 991px) {
    .container {
      padding-top: $px15;
      padding-bottom: $px15;

      > .wrapper {
        flex-direction: column;

        .lnb {
          background: #fff;
          box-shadow: 0 0 1.125rem 0 rgba(136, 152, 170, 0.15);
          border-radius: $px16;
          padding: $px20 $px15;
          position: relative;
          overflow: hidden;
          margin: 0 0 $px15 0;
          width: 100%;
          height: $px120;
          transition: height 0.18s;
          z-index: 1;

          > .wrapper {
            font-size: 0;
            padding: 0;

            .part {
              padding: 0;

              .subject {
                padding: 0 0 0 $px6;
                font-weight: 600;
                margin-bottom: $px10;
              }

              ul {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: $px24 $px16;

                li {
                  margin: 0;
                  width: calc((100% - ($px16 * 3)) / 4.2);

                  > a {
                    flex-direction: column;
                    white-space: nowrap;

                    > .img {
                      width: $px28;
                      height: $px28;
                    }

                    .txt {
                      font-size: $px13;
                    }
                  }
                }
              }
            }
          }

          > .toggle {
            position: absolute;
            width: fit-content;
            padding: $px20 $px4;
            bottom: $px20;
            right: $px15;
            background: #fff;

            &:before {
              position: absolute;
              top: 0;
              left: 0;
              transform: translateX(-98%);
              height: 100%;
              width: $px50;
              content: "";
              display: block;
              background: #fff;
              background: linear-gradient(to left, #fff 20%, hsla(0deg, 0%, 100%, 0) 80%);
            }
          }

          &.expand {
            height: auto;
          }

          &.Dashboard {
            display: none;
          }
        }

        .content {
          border-radius: $px16;
          width: 100%;
          margin-top: 0;
          margin-bottom: $px15;
          padding-bottom: $px25;
          border: $px1 solid #eee;
          min-height: 595px;

          .head {
            padding: $px15 $px15 0 $px15;

            .header {
              padding: $px5;

              .title {
                font-size: $px18;
              }
            }

            .profile {
              display: none;
            }
          }

          .body {
            padding: $px15 $px15 0 $px15;
          }
        }
      }
    }
  }

  &.skeleton {
    > .container > .wrapper .lnb > .wrapper .part {
      .subject {
        @include skeleton;
      }

      ul li > a > span {
        @include skeleton;
      }
    }
  }
}
</style>