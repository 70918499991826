<template>
  <div class="home">
    <div class="container">
      <div class="slider">
        <Slider/>
      </div>
    </div>
    <div class="content">
      <div class="container">
        <div class="section menu d-lg-none">
          <router-link :to="c.link" class="item no-underline" v-for="c in categories" :key="`HomeMobileCategory${c.name}`">
            <div class="bg">
              <span class="img" :style="{backgroundImage: `url(${c.ico})`}"></span>
            </div>
            <b class="font-sm pt-2 no-underline d-inline-block">{{ c.title }}</b>
          </router-link>
        </div>
        <div class="section project recommend" :class="{skeleton: !state.projects.recommend.loaded}">
          <div class="title">
            <h3>지금, 참여해야 할 추천 펀딩</h3>
          </div>
          <template v-if="state.projects.recommend.loaded">
            <ul class="cards no-scrollbar tight" v-if="state.projects.recommend.list.length">
              <li v-for="(p, idx) in state.projects.recommend.list" :key="`homeRecommend${idx}${p.projectSeq}`">
                <!-- idx + 1 하는 이유 : 하단의 스켈레톤 UI와 키를 맞추기 위해(progress-bar 애니메이션 효과 가능)-->
                <CombinedCard
                    page="home"
                    :projectSeq="p.projectSeq"
                    :link="p.linkUrl"
                    :thumbFilePath="p.thumbFilePath"
                    :amount="p.investAmt"
                    :projectName="p.projectName"
                    :progressOrder="p.progressOrder"
                    :projectCate="p.projectCate"
                    :fundingType="p.fundingType"
                    :count="p.investorCount"
                    :percent="Number(p.per)"
                    :dday="Number(p.dday) === 0 ? 0 : Number(p.dday || p.dDay)"
                    :builderName="p.builderName"
                    :builderImageUrl="p.builderImageUrl"
                    :builderSeq="p.builderSeq"
                    :projectType="p.projectType"
                    :mainImagePosition="p.mainImagePosition"
                    alignment="mobile-horizontal"
                />
              </li>
            </ul>
            <div class="pt-50 pb-50" v-else>
              <NoData/>
            </div>
          </template>
          <ul class="cards no-scrollbar tight" v-else>
            <li v-for="i in 4" :key="i">
              <CombinedCard
                  :skeleton="true"
                  builderName="wait a moment"
                  projectName="wait"
                  projectCate="wait"
                  page="home"
                  :count="0"
                  :amount="0"
                  :percent="0"
                  alignment="mobile-horizontal"
              />
            </li>
          </ul>
        </div>
        <div class="section project new" :class="{skeleton: !state.projects.new.loaded}">
          <div class="title">
            <h3>신규 펀딩</h3>
          </div>
          <template v-if="state.projects.new.loaded">
            <ul class="cards no-scrollbar tight" v-if="state.projects.new.list.length">
              <li v-for="(p, idx) in state.projects.new.list" :key="idx + 1">
                <CombinedCard
                    page="home"
                    :projectSeq="p.projectSeq"
                    :link="p.linkUrl"
                    :thumbFilePath="p.thumbFilePath"
                    :amount="p.targetAmt"
                    :projectName="p.projectName"
                    :progressOrder="p.progressOrder"
                    :fundingType="p.fundingType"
                    :count="p.investorCount"
                    :percent="p.per"
                    :dday="Number(p.dday)"
                    :builderImageUrl="p.builderImageUrl"
                    :builderSeq="p.builderSeq"
                    :projectType="p.projectType"
                    :mainImagePosition="p.mainImagePosition"
                />
              </li>
            </ul>
            <div class="pt-50 pb-50" v-else>
              <NoData/>
            </div>
          </template>
          <ul class="cards no-scrollbar tight" v-else>
            <li v-for="i in 4" :key="i">
              <CombinedCard :skeleton="true" projectName="Wait a moment"/>
            </li>
          </ul>
        </div>
        <div class="section project popular" :class="{skeleton: !state.projects.popular.loaded}">
          <div class="title">
            <h3>
              <span>수많은 사람들의 Pick! </span>
              <span class="d-block d-lg-inline-block">인기 펀딩</span>
            </h3>
            <div class="side" v-if="state.projects.popular.category.visible">
              <label class="badge m-0 pointer" v-for="(c, idx) in state.projects.popular.category.list" :key="idx"
                     :for="`${component.name + c.name}popular${idx}`" :class="{'badge-point-soft' : c.name === state.projects.popular.category.active}">
                <input class="d-none" type="radio" :id="`${component.name + c.name}popular${idx}`" v-model="state.projects.popular.category.active" :value="c.name" @change="changeCategory()" :disabled="!state.projects.popular.loaded">
                <span class="font-xs">{{ c.title }}</span>
              </label>
            </div>
          </div>
          <template v-if="state.projects.popular.loaded">
            <ul class="cards no-scrollbar tight" v-if="state.projects.popular.list.length">
              <li v-for="(p, idx) in state.projects.popular.list" :key="idx + 1">
                <CombinedCard
                    page="home"
                    :projectSeq="p.projectSeq"
                    :link="state.projects.popular.category.active === 'reward' ? '/reward/' + p.projectSeq : '/invest/' + p.investSeq"
                    :thumbFilePath="p.thumbFilePath"
                    :amount="p.expenseAmt"
                    :projectName="p.projectName"
                    :progressOrder="p.progressOrder ? Number(p.progressOrder) : p.progressOrder"
                    :projectCate="p.projectCateName"
                    :fundingType="p.fundingType"
                    :count="p.investorCount"
                    :percent="state.projects.popular.category.active === 'reward'? Number(p.per): Number(p.subscribeRate)"
                    :dday="Number(p.dday) === 0 ? 0 : Number(p.dday || p.dDay)"
                    :builderName="p.builderName"
                    :builderImageUrl="p.builderImageUrl"
                    :builderSeq="p.builderSeq"
                    :projectType="state.projects.popular.category.active"
                    :hideInterest="p.fundType === '02'"
                    :mainImagePosition="p.mainImagePosition"
                />
              </li>
            </ul>
            <div class="pt-50 pb-50" v-else>
              <NoData/>
            </div>
          </template>
          <ul class="cards no-scrollbar tight" v-else>
            <li v-for="i in 4" :key="i">
              <CombinedCard :skeleton="true"
                            builderName="wait a moment"
                            projectName="wait"
                            projectCate="wait"
                            page="home"
                            :count="0"
                            :amount="0"
                            :percent="0"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="banner" v-if="state.banner.title">
        <div class="container">
          <router-link class="shadow" :to="state.banner.bannerUrl">
            <span class="background" :style="{ backgroundImage: `url(${state.banner.imagePath + state.banner.imageSaveName})` }" v-if="state.banner.imageSaveName"></span>
          </router-link>
          <div class="info">
            <div class="subject">
              <router-link :to="state.banner.bannerUrl" class="no-underline">
                <div class="title"><b>{{ state.banner.title }}</b></div>
                <div class="desc color-secondary"><span>{{ state.banner.communityDesc }}</span></div>
              </router-link>
            </div>
            <router-link :to="state.banner.bannerUrl" class="btn btn-default font-sm d-none d-lg-flex">
              <span>지금 바로가기</span>
              <span class="img" style="background-image: url(/assets/ico/page.main.home.right.arrow.svg)"></span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="section planning" :class="{skeleton: !state.projects.planning.loaded}">
          <div class="title">
            <h3>기획전</h3>
          </div>
          <HomePlanning :loaded.sync="state.projects.planning.loaded" :runWhenEmpty="hidePlanning"/>
        </div>
        <div class="section project reservation" :class="{skeleton: !state.projects.new.loaded}" v-if="!state.projects.reservation.loaded || state.projects.reservation.list.length">
          <div class="title">
            <h3>
              <router-link class="no-underline" to="/reservation/list">곧 오픈할 새로운 펀딩을 예약하세요!</router-link>
            </h3>
            <div class="side">
              <router-link to="/reservation/list">
                <span class="img" style="background-image: url(/assets/ico/page.main.home.right.arrow.svg)"></span>
              </router-link>
            </div>
          </div>
          <ul class="cards no-scrollbar tight" v-if="state.projects.reservation.loaded">
            <li v-for="p in computedReservationProjects" :key="'r' + p.reservationSeq">
              <ReservationCard
                  page="home"
                  ratio="square"
                  :applyFlag="p.applyFlag.toString()"
                  :applyCnt="Number(p.applyCnt)"
                  :clickable="Boolean(p.content) || true"
                  :item="p.item"
                  :memberSeq="p.memberSeq"
                  :memberName="p.corporateName || p.memberName"
                  :reservationCate="p.reservationCate"
                  :reservationSeq="p.reservationSeq"
                  :link="`/reservation/${p.reservationSeq}`"
                  :thumbFilePath="p.thumbnailPath"
                  :projectName="p.projectName"
                  :projectSeq="p.projectSeq.toString()"
                  :mainImagePosition="p.mainImagePosition"
              />
            </li>
          </ul>
          <ul class="cards no-scrollbar tight" v-else>
            <li v-for="i in 4" :key="i">
              <ReservationCard :skeleton="true" projectName="Wait a moment"/>
            </li>
          </ul>
        </div>
      </div>
      <div class="container bg">
        <div class="section news" :class="{skeleton: !state.projects.news.loaded}" v-if="state.projects.news.list">
          <div class="title">
            <h3>새로운 소식이 도착했어요</h3>
          </div>
          <div class="no-scrollbar tidings">
            <div class="tiding" v-for="(n, idx) in state.projects.news.list" :key="idx" @click="openNews(n, n.skeleton)" :class="{skeleton: n.skeleton}">
              <div class="thumbnail">
                <span class="img" :style="{backgroundImage: `url(${computedThumbnailUrl(n.thumbFilePath)})`, backgroundPosition: n.mainImagePosition || 'center'}"></span>
              </div>
              <div class="info">
                <div class="subject">
                  <span class="project-name font-xs color-secondary">{{ n.projectName }}</span>
                </div>
                <b class="title">{{ n.title }}</b>
                <span class="font-xs color-secondary d-block mt-2">{{ n.createDate }}</span>
              </div>
            </div>
          </div>
          <div class="actions" :class="{skeleton: state.projects.news.more || !state.projects.news.loaded}">
            <span class="pointer font-sm p-2" @click="!(state.projects.news.more || !state.projects.news.loaded) && loadNews(true)"
                  v-if="state.projects.news.loaded && state.projects.news.paginationInfo && state.projects.news.paginationInfo.lastPageNo > state.projects.news.args.pageIndex">더보기 +
            </span>
            <span class="fold font-sm" v-if="state.projects.news.args.pageIndex > 1" @click="loadNews()">접기</span>
          </div>
        </div>
        <div class="section partners" :class="{skeleton: !state.projects.partnership.loaded}">
          <div class="title">
            <h3>
              <router-link class="no-underline" to="/partnership/partnerlist">오마이컴퍼니와 함께하는 파트너</router-link>
            </h3>
            <div class="side">
              <router-link to="/partnership/partnerlist">
                <span class="img" style="background-image: url(/assets/ico/page.main.home.right.arrow.svg)"></span>
              </router-link>
            </div>
          </div>
          <Partnership :loaded.sync="state.projects.partnership.loaded"/>
        </div>
      </div>
    </div>
    <div class="news-letter" :id="`${component.name}NewsLetter`">
      <a href="https://ohmarket.stibee.com/" class="ico" target="_blank" rel="noopener noreferrer" title="뉴스레터 바로가기"></a>
    </div>
    <div class="popup" v-if="state.popup">
      <div class="wrapper">
        <b>&lt;긴급공지&gt;</b>
        <div>금일 접속량 폭증으로 인해 오후 5시 이후부터 페이지 로딩 속도가 느려지고 있습니다. 회원님들의 이용에 불편함을 드려 죄송합니다.</div>
        <div>현재 문제점을 파악하여 해결중이오니 조금만 기다려주시면 감사하겠습니다.</div>
      </div>
      <div class="bottom">
        <div class="wrapper">
          <a @click="state.popup = false">닫기</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import Slider from "@/components/Slider.vue";
import Card from "@/components/Card.vue";
import NoData from "@/components/NoData.vue";
import HomePlanning from "./HomePlanning.vue";
import Partnership from "./HomePartnership.vue";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import ReservationCard from "@/components/ReservationCard";
import track from "@/scripts/track";
import MemberPicture from "@/components/MemberPicture";
import store from "@/scripts/store";
import env from "@/scripts/env";
import lib from "@/scripts/lib";
import CombinedCard from "@/components/CombinedCard.vue";

function Component(initialize) {
  this.name = "pageMainHome";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {CombinedCard, MemberPicture, ReservationCard, Slider, Card, NoData, HomePlanning, Partnership},
  setup() {
    const component = new Component(() => {
      // console.log(lib.getDecodedObject(`eyJzb3VyY2UiOiJnb29nbGUiLCJtZWRpdW0iOiJkaXNwbGF5IiwiY2FtcGFpZ24iOiJyZXdhcmQtMTI5OTUiLCJjb250ZW50IjoiIiwidGVybSI6IiJ9`));
      // console.log(lib.getEncodedString({
      //   source: "banner",
      //   medium: "main",
      //   campaign: "PCNC",
      //   content: "",
      //   term: "",
      // }));

      const promises = [];
      const categories = ["recommend", "popular", "new", "reservation"];

      loadNews();

      http.get("/api/main/popular-tab", undefined, {cache: true}).then(({data}) => {
        state.projects.popular.category.visible = data.body === "Y";
      });

      http.get("/api/main/banners/middle", undefined, {cache: true}).then(({data}) => {
        if (!data?.body?.length || !data.body[0].title) {
          return;
        }

        state.banner = data.body[0];

        if (state.banner.bannerUrl?.startsWith("https://")) {
          state.banner.bannerUrl = new window.URL(state.banner.bannerUrl).pathname;
        }
      });

      for (let c of categories) {
        const args = {
          category: c,
          projectType: "reward",
        };

        state.projects[c].loaded = false;
        promises.push(http.get("/api/main/projects", args, {cache: true}));
      }

      Promise.all(promises).then((responses) => {
        for (let i in responses) {
          state.projects[categories[i]].loaded = true;
          state.projects[categories[i]].list = [];

          for (let d of responses[i].data.body) {
            state.projects[categories[i]].list.push(d);
          }
        }

        track.post({
          name: "mainHome",
          category: "메인",
          topic: "조회"
        });
      });
    });

    const state = reactive({
      popup: false,
      projects: {
        recommend: {
          list: [],
          loaded: false,
          category: {
            visible: false,
            active: "",
            list: [],
          },
        },
        popular: {
          list: [],
          loaded: false,
          category: {
            visible: false,
            active: "reward",
            list: [
              {name: "reward", title: "후원형"},
              {name: "invest", title: "증권형"},
            ],
          },
        },
        new: {
          list: [],
          loaded: false,
          category: {
            visible: false,
            active: "",
            list: [],
          },
        },
        reservation: {
          list: [],
          loaded: false,
        },
        planning: {
          loaded: false,
        },
        partnership: {
          loaded: false,
        },
        news: {
          loaded: false,
          more: false,
          list: [],
          paginationInfo: null,
          args: {
            pageIndex: 1,
            pageSize: 5,
            pageUnit: 6,
          }
        }
      },
      banner: {
        title: "",
        communityDesc: "",
        imagePath: "",
        imageSaveName: "",
        bannerUrl: ""
      }
    });

    const computedReservationProjects = computed(() => {
      const limit = state.projects.reservation.list.length >= 8 ? 8 : 4;
      return state.projects.reservation.list.filter((p, idx) => idx < limit);
    });

    const computedThumbnailUrl = computed(() => {
      return (thumbFileUrl) => {
        return store.getters.thumbnailUrl(thumbFileUrl);
      };
    });

    const categories = [
      {
        name: "reward",
        title: "후원하기",
        ico: "/assets/ico/page.main.home.category.reward.svg",
        link: "/reward/list",
      },
      {
        name: "invest",
        title: "투자하기",
        ico: "/assets/ico/page.main.home.category.invest.svg",
        link: "/invest/list",
      },
      {
        name: "reservation",
        title: "오픈예정",
        ico: "/assets/ico/page.main.home.category.reservation.svg",
        link: "/reservation/list",
      },
      {
        name: "partnership",
        title: "기획전",
        ico: "/assets/ico/page.main.home.category.partnership.svg",
        link: "/partnership/partnerlist",
      }
    ];

    const loadNews = (more) => {
      if (env.device === "mobile") {
        state.projects.news.args.pageUnit = 4;
      }

      for (let i = 0; i < state.projects.news.args.pageUnit; i += 1) {
        state.projects.news.list.push({
          projectName: "Wait a moment",
          projectType: "Wait",
          createDate: "0000-00-00",
          title: "Wait a moment",
          skeleton: true,
        });
      }

      if (more) {
        state.projects.news.args.pageIndex += 1;
        state.projects.news.more = true;
      } else {
        state.projects.news.args.pageIndex = 1;
        state.projects.news.loaded = false;
      }

      http.get("/api/main/news", state.projects.news.args).then(({data}) => {
        if (more) {
          state.projects.news.more = false;
          state.projects.news.list = state.projects.news.list.filter(n => !n.skeleton);
          for (let i in data.body.list) {
            state.projects.news.list.push(data.body.list[i]);
          }
          return;
        }

        state.projects.news.loaded = true;
        state.projects.news.list = data.body.list;
        state.projects.news.paginationInfo = data.body.paginationInfo;
      });
    };

    const openNews = (news, loading) => {
      if (loading || !state.projects.news.loaded) {
        return;
      }

      store.commit("openModal", {
        name: "News",
        params: {
          projectSeq: news.projectSeq,
          projectType: news.projectType,
          newsSeq: news.newsSeq,
          page: "home"
        }
      });
    };

    const changeCategory = () => {
      const args = {
        category: "popular",
        projectType: state.projects.popular.category.active,
      };

      state.projects.popular.loaded = false;

      http.get("/api/main/projects", args, {cache: true}).then(({data}) => {
        state.projects.popular.loaded = true;
        state.projects.popular.list = data.body;
      }).catch(httpError(() => {
        state.projects.popular.loaded = true;
      }));
    };

    const hidePlanning = () => {
      document.querySelector(".section.planning")?.remove();
    };

    const onScroll = () => {
      const $newsLetter = document.getElementById(`${component.name}NewsLetter`);
      lib.isClimbable() ? $newsLetter.style.bottom = "70px" : $newsLetter.style.bottom = "0";
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    return {
      component
      , state
      , computedReservationProjects
      , computedThumbnailUrl
      , categories
      , changeCategory
      , openNews
      , loadNews
      , hidePlanning
    };
  },
});
</script>

<style lang="scss" scoped>
$gapUnderTitle: $px20;
$gapUnderTitleMobile: $px18;

.home {
  --gapUnderSection: 68px;

  > .container > .slider {
    border-radius: $px24;
    margin-top: $px30;
    margin-bottom: var(--gapUnderSection);
    overflow: hidden;
  }

  .container .section {
    margin-bottom: var(--gapUnderSection);
  }

  > .content {
    .section {
      width: 100%;

      > .title {
        position: relative;
        margin-bottom: $gapUnderTitle;
        padding-left: $px2;
        display: flex;
        justify-content: space-between;
        gap: $px32;

        h3 {
          margin: 0;
          font-size: $px22;
          font-weight: 600;
        }
      }

      &.menu {
        display: flex;
        gap: $px16;
        justify-content: space-between;
        align-items: center;

        .item {
          text-align: center;

          > .bg {
            background-color: #f2f2f2;
            width: $px64;
            height: $px64;
            border-radius: $px25;
            display: flex;
            justify-content: center;
            align-items: center;

            > .img {
              width: $px32;
              height: $px32;
            }
          }
        }
      }

      &.project {
        .cards {
          display: flex;
          gap: $px24;

          > li {
            width: calc((100% - $px24 * 3) / 4);
          }
        }

        &.popular {
          > .title {
            align-items: flex-start;
          }

          .side {
            display: flex;
            gap: $px8;

            .badge {
              border-radius: $px100;
              padding: $px6 $px12;

              &:not(.badge-point-soft) {
                border: $px1 solid #eee;

                &:hover {
                  background-color: #eee;
                }
              }
            }
          }
        }

        &.new {
          .cards li::v-deep {
            width: calc(100% / 3);

            > .card .inner .panel {
              .above {
                height: auto !important;
              }

              .tags, .bottom {
                display: none;
              }
            }

            &:nth-child(4) {
              display: none;
            }
          }
        }
      }

      &.reservation, &.partners {
        > .title {
          .side {
            font-size: 0;

            a > .img {
              width: $px24;
              height: $px24;
            }
          }
        }
      }

      &.partners {
        margin-bottom: 0;
        padding-bottom: var(--gapUnderSection);
      }

      &.news {
        .tidings {
          display: flex;
          flex-wrap: wrap;
          gap: $px16;

          .tiding {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: calc(50% - $px8);

            .thumbnail {
              flex-shrink: 0;
              font-size: 0;
              overflow: hidden;
              width: 25%;
              border-radius: $thumbBorderRadius;

              > .img {
                padding-top: $ratio43Percent;
                width: 100%;
                background-color: #f7f7f7;
                transition: transform 0.18s;

                &:hover {
                  transform: scale(1.1);
                }
              }
            }

            .info {
              flex-grow: 1;
              padding: $px8 $px16;
              overflow: hidden;

              .subject {
                .project-name {
                  display: block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              > .title {
                display: block;
                margin-top: $px4;
              }
            }

            &.skeleton {
              cursor: default;

              .thumbnail > .img {
                @include skeleton;
              }

              .info {
                span, > .title {
                  @include skeleton;
                }
              }
            }
          }
        }

        > .actions {
          margin-top: $px30;
          position: relative;
          text-align: center;

          > .fold {
            cursor: pointer;
            padding: $px8;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          &.skeleton {
            > span {
              @include skeleton;
            }
          }
        }
      }

      &.skeleton {
        .title {
          a, h3, span {
            @include skeleton;
          }
        }

        .side {
          a, label {
            @include skeleton;
          }
        }
      }
    }

    .banner {
      margin-bottom: var(--gapUnderSection);
      background-color: #f2f9f7;
      padding-top: $px35;
      padding-bottom: $px35;

      .container {
        display: flex;
        gap: $px48;
        position: relative;
        min-height: $px230;

        > a {
          display: block;
          flex-shrink: 0;
          width: $px510;
          position: relative;
          text-decoration: none;
          border-radius: $px16;
          overflow: hidden;

          .background {
            display: block;
            background-color: $colorBackground;
            background-size: cover;
            background-position: center;
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
          }
        }

        .info {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: $px16;
          padding-top: $px32;
          padding-bottom: $px32;

          .subject {
            word-break: keep-all;

            .title {
              font-size: $px24;
              font-weight: 600;
            }

            .desc {
              font-size: $px18;
              font-weight: 500;
              margin-top: $px5;
            }
          }

          .btn {
            background-color: #fff;
            border-radius: $px100;
            padding: $px8 $px16;
            width: fit-content;
            white-space: nowrap;
            display: flex;
            gap: $px8;
            align-items: center;

            > .img {
              width: $px16;
              height: $px16;
            }
          }
        }
      }
    }
  }

  > .news-letter {
    position: fixed;
    right: 0;
    bottom: 0;
    width: $px140;
    height: $px140;
    z-index: 1;
    transition: bottom 0.18s;

    .ico {
      background: url("/assets/ico/page.main.home.news-letter.svg") center/contain no-repeat;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    max-width: 540px;
    border: 1px solid #eee;
    border-top: 7px solid #27caa1;
    background: #fff;

    b {
      display: block;
      padding-bottom: 5px;
      font-weight: bold;
    }

    > .wrapper {
      padding: 30px;
    }

    .bottom {
      display: flex;
      width: 100%;
      border-top: 1px solid #eee;

      > div {
        width: 100%;
        text-align: center;

        a {
          display: block;
          padding: 15px;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 991px) {
    --gapUnderSection: 48px;

    > .container > .slider {
      margin-top: $px24;
    }

    > .content {
      .section {
        > .title {
          margin-bottom: $gapUnderTitleMobile;

          h3 {
            font-size: $px18;
          }

          .desc {
            margin-top: $px4;
            font-size: $px12;
          }
        }

        &.project {
          .cards {
            margin: 0 -15px;
            white-space: nowrap;
            overflow: auto;
            gap: $px15;

            li {
              white-space: normal;
              width: calc(100% / 2.5);
              flex-shrink: 0;

              &:first-child {
                margin-left: 15px;
              }

              &:last-child {
                margin-right: 15px;
              }
            }
          }

          &.popular {
            .cards {
              flex-direction: column;
              margin: inherit;
              gap: $px24;

              li {
                width: 100%;

                &:first-child, &:last-child {
                  margin: 0;
                }
              }
            }
          }

          &.recommend {
            .cards {
              flex-direction: column;
              margin: inherit;
              gap: $px24;

              li {
                width: 100%;

                &:first-child, &:last-child {
                  margin: 0;
                }
              }
            }
          }

          &.new {
            .cards {
              li {
                width: calc(100% / 2.5);

                &:nth-child(4) {
                  display: inline-block;
                }
              }
            }
          }
        }

        &.news {
          .tidings .tiding {
            width: 100%;
          }

          > .actions {
            margin-top: $px25;
          }
        }

        &.partners {
          padding-bottom: 0;
        }
      }

      .banner {
        .container {
          min-height: auto;
          flex-direction: column;
          gap: $px16;

          > a {
            padding-top: 45.1%;
            width: 100%;
            order: 1;
          }

          .info {
            flex-direction: column;
            padding: 0;
            order: 0;

            .subject {
              .title {
                font-size: $px18;
                margin-bottom: $px4;
              }

              .desc {
                font-size: $px14;
              }
            }
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    > .content {
      .container {
        &.bg {
          background-color: #f2f9f7;
          border-radius: $px24 $px24 0 0;
          padding-top: $px35;
          padding-bottom: $px35;
        }
      }

      .section {
        &.news {
          .tidings {
            gap: $px10;

            .tiding {
              .thumbnail {
                width: 35%;
              }

              .info {
                > .title {
                  font-size: $px14;
                }
              }
            }
          }
        }
      }
    }

    > .news-letter {
      display: none;
    }
  }
}
</style>