<template>
  <div class="ground" :class="$store.state.classes.ground" :style="$store.state.styles.ground">
    <Header v-show="computedLayoutVisible"/>
    <RouterView :key="$route.path"/>
    <Footer v-show="computedLayoutVisible"/>
    <Climbing/>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted} from "@vue/composition-api";
import Header from "@/layouts/ground/Header";
import Footer from "@/components/Footer";
import Climbing from "@/components/Climbing";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import {observe} from "../../scripts/observer";
import storage from "@/scripts/storage";

function Component(initialize) {
  this.name = "layoutGround";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Header, Footer, Climbing},
  setup() {
    const component = new Component(() => {
    });

    const setGroundPaddingTop = (value) => {
      store.commit("setComponentStyle", {
        to: "ground",
        key: "paddingTop",
        value: value + "px"
      });
    };

    const computedLayoutVisible = computed(() => {
      return storage.get("session", "layout") !== "hidden";
    });

    onMounted(() => {
      const $header = document.querySelector("header");
      setGroundPaddingTop(window.innerWidth <= 991 ? 59 : 87);

      $header && observe($header, (target) => {
        setGroundPaddingTop(target.getBoundingClientRect().height);
      });
    });

    return {component, computedLayoutVisible};
  },
});
</script>

<style lang="scss" scoped>
.ground {
  @media (max-width: 991px) {
    &.mobile-no-padding-top {
      padding-top: 0 !important;
    }
  }
}
</style>